import React, { useState, useEffect } from "react";

export const ConfirmAlert = ({openDeleteAlert, onCloseDeleteAlert, isPerformingAction, onAction, orderMessage}) => {
  const [showModal, setShowModal] = useState(openDeleteAlert);
  
  useEffect(() => {
    setShowModal(openDeleteAlert);
  }, [openDeleteAlert]);
  
  return (
    <>
      {openDeleteAlert ? (
        <>
            <div className="relative z-10" aria-labelledby="modal-title" role="dialog" aria-modal="true">

                <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity"></div>

                <div className="fixed inset-0 z-10 overflow-y-auto">
                    <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
                        <div className="relative transform overflow-hidden rounded-lg bg-white text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg">
                            <div className="bg-white px-4 pb-4 pt-5 sm:p-6 sm:pb-4">
                                <div className="sm:flex sm:items-start">
                                    <div className="mx-auto flex h-12 w-12 flex-shrink-0 items-center justify-center rounded-full bg-red-100 sm:mx-0 sm:h-10 sm:w-10">
                                    <svg className="h-6 w-6 text-red-600" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" aria-hidden="true">
                                        <path stroke-linecap="round" stroke-linejoin="round" d="M12 9v3.75m-9.303 3.376c-.866 1.5.217 3.374 1.948 3.374h14.71c1.73 0 2.813-1.874 1.948-3.374L13.949 3.378c-.866-1.5-3.032-1.5-3.898 0L2.697 16.126zM12 15.75h.007v.008H12v-.008z" />
                                    </svg>
                                    </div>
                                    <div className="mt-3 text-center sm:ml-4 sm:mt-0 sm:text-left">
                                        <h3 className="text-base font-semibold leading-6 text-gray-900" id="modal-title">Delete product permananetly</h3>
                                        {orderMessage ? <div className="mt-2">
                                            <p className="text-sm text-gray-500">Are you sure you want to delete this product from your app? If the product is approved then it will be deleted from RvOnline shopify store.</p>
                                            <p className="text-sm text-red-700">Note: If there is any pending order with this product then vendor cannot be fulfill it.</p>
                                        </div> : <div className="mt-2">
                                            <p className="text-sm text-gray-500">Are you sure you want to delete this product from your app?</p>
                                        </div>}
                                    </div>
                                </div>
                            </div>
                            <div className="bg-gray-50 px-4 py-3 sm:flex sm:flex-row-reverse sm:px-6">
                                <button type="button" className="inline-flex w-full justify-center rounded-md bg-red-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-red-500 sm:ml-3 sm:w-auto" onClick={onAction}>
                                    { isPerformingAction === true ? <>
                                        <svg className="animate-spin h-4 w-4 mr-1 text-white" viewBox="0 0 24 24">
                                            <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4" />
                                            <path
                                                className="opacity-75"
                                                fill="currentColor"
                                                d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-1.647zm4-1.647l3 1.647C11.865 17.824 13 15.042 13 12H8a7.962 7.962 0 01-.729 3.291z"
                                            />
                                        </svg>
                                        <span>deleting...</span>
                                    </> : "Yes, delete it"}
                                </button>
                                <button type="button" className="mt-3 inline-flex w-full justify-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 sm:mt-0 sm:w-auto" onClick={onCloseDeleteAlert}>Cancel</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
      ) : null}
    </>
  );
};