import React, { useState, useEffect } from "react";
import useAuth from '../../hooks/auth-hook';
import { settingFields } from "../../utils/settingFields";
import Input from "../../contexts/Input";
import FormAction from '../../contexts/FormAction';
import Breadcrumb from '../../contexts/Breadcrumb';
import { getStoreSetting, updateStoreSetting } from '../../utils/api';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Loader from '../../contexts/Loader';

const Setting = () => {
    const Auth = useAuth();
    const { role, name } = useAuth();

    const [showLoader, setShowLoader] = useState(true);
    const requiredFields = ['shop_name', 'shopify_api_key', 'shopify_secret_key', 'shopify_access_token', 'location_id'];
    const [isFormValid, setIsFormValid] = useState(true);
    const [successMessage, setSuccessMessage] = useState(false);
    const [error, setError] = useState(null);
    const [data, setData] = useState({
        shop_name: null,
        shopify_api_key: null,
        shopify_secret_key: null,
        shopify_access_token: null,
        location_id: null
    });

    useEffect(() => {
        handleFetchData();
    }, []);

    const fields = settingFields;
    const [settingField, setSettingField] = useState(Object.fromEntries(fields.map((field) => [field.name, ""])));

    const handleChange = (e) => {
        setSettingField({ ...settingField, [e.target.name]: e.target.value });
        setData({ ...data, [e.target.name]: e.target.value });
        //button enable
        const formValues = Object.values(settingField);
        const isFormValid = requiredFields.every((field) => formValues.includes(field));
        setIsFormValid(isFormValid);
    };

    const handleSubmit = (e) => {
        e.preventDefault();

        for (const field of requiredFields) {
            // console.log(data[field])
            if (!data[field]) {
                console.log(data[field], settingField[field], data[field])
                return setError(`${field.replace('_', ' ')} field is required.`);
            }
        }
        updateData();
    }


    const updateData = async () => {
        try {
            const response = await updateStoreSetting(data);
            // console.log(response);
            toast.dismiss();
            if (response.status == 201) {
                toast.success("Settings Updated", {
                    position: toast.POSITION.TOP_CENTER
                });
            }
            else {
                toast.error(response.error, {
                    position: toast.POSITION.TOP_CENTER
                });
            }
        }
        catch (error) {
            toast.dismiss();
            toast.error("Error", {
                position: toast.POSITION.TOP_CENTER
            });
        }

    };

    const handleFetchData = async (queryString) => {
        const response = await getStoreSetting(queryString);
        
        setData({
            shop_name: response.data?.[0]?.shop_name,
            shopify_api_key: response.data?.[0]?.shopify_api_key,
            shopify_secret_key: response.data?.[0]?.shopify_secret_key,
            shopify_access_token: response.data?.[0]?.shopify_access_token,
            location_id: response.data?.[0]?.location_id,
        });
        setError(response.error);
    };
    return (
        <>

            <div className='border-b pb-6'>
                <div className="flex flex-col items-start justify-between pb-2 space-y-4  lg:items-center lg:space-y-0 lg:flex-row">
                    <h1 className="text-2xl font-semibold whitespace-nowrap">Settings</h1>
                    <div className="space-y-6 md:space-x-2 md:space-y-0">
                        <span className="inline-flex items-center justify-center px-4 py-1 space-x-1"><strong>Role : </strong> {role}</span>
                    </div>
                </div>
                <Breadcrumb />
            </div>
            {
                showLoader ?
                    (<Loader setShowLoader={setShowLoader} />) :
                    (<>
                        <div className="grid grid-cols-12 gap-5">
                            <div className="col-span-12 lg:col-span-6 mt-8 space-y-6">
                                <div className="max-w-md">
                                    <h2 className="text-lg font-medium mb-4">How to get your Shopify API credentials</h2>
                                    <ol className="list-decimal pl-6 mb-6 ">
                                        <li className="mb-2 text-gray-800">Log in to your Shopify admin panel at <a href="#" className="text-blue-500 hover:underline">https://your-store-name.myshopify.com/admin</a>.</li>
                                        <li className="mb-2 text-gray-800">Click on "Apps" from the left-hand navigation menu.</li>
                                        <li className="mb-2 text-gray-800">Click on "Manage private apps" at the bottom of the page.</li>
                                        <li className="mb-2 text-gray-800">Click on "Create a new private app" button on the top right corner of the page.</li>
                                        <li className="mb-2 text-gray-800">Enter a name for your private app in the "App name" field.</li>
                                        <li className="mb-2 text-gray-800">Enter your email address in the "Emergency developer email" field.</li>
                                        <li className="mb-2 text-gray-800">In the "Admin API" section, enable the areas of your store that you want the app to access. This will determine the permissions of the API credentials.</li>
                                        <li className="mb-2 text-gray-800">In the "Admin API" section, click on "Generate API credentials".</li>
                                        <li className="mb-2 text-gray-800">Your api_key and api_password will be generated. Make sure to save them in a secure location.</li>
                                        <li className="mb-2 text-gray-800">Scroll down to the "Authentication" section to view your api_secret key.</li>
                                        <li className="mb-2 text-gray-800">The store_name is the first part of your store's URL, before .myshopify.com. For example, if your store's URL is <code>https://example.myshopify.com</code>, your store_name is <code>example</code>.</li>
                                    </ol>
                                    <p className="text-sm text-gray-600">Note: Keep in mind that the api_key, api_password, and api_secret are sensitive credentials and should be kept confidential. Do not share them with anyone who doesn't need access to them, and make sure to store them securely.</p>
                                </div>

                            </div>
                            <div className="col-span-12 lg:col-span-6">
                                <form className="bg-gray-50 mt-8 space-y-6 rounded-md shadow-md border p-4 py-6 sticky top-0" onSubmit={handleSubmit}>
                                    <div className="-space-y-px">
                                        {fields.map((field) => (
                                            <div key={field.name}>
                                                <label
                                                    htmlFor={field.name}
                                                    className="block text-sm font-medium text-gray-700"
                                                >
                                                    {field.labelText}
                                                </label>
                                                <div className="mt-1">
                                                    <Input
                                                        handleChange={handleChange}
                                                        value={data[field.name] || ""}
                                                        labelFor={field.labelFor}
                                                        id={field.id}
                                                        name={field.name}
                                                        type={field.type}
                                                        placeholder={field.placeholder}
                                                        className="block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:border-indigo-500 focus:ring-1 focus:ring-indigo-500 sm:text-sm"
                                                    />
                                                </div>
                                            </div>
                                        ))}
                                    </div>

                                    {error &&
                                        <div className="bg-red-100 border border-red-200 text-red-700 px-2 py-2 rounded relative" role="alert">
                                            <span className="block sm:inline text-xs">{error}</span>
                                        </div>
                                    }
                                    <FormAction text="Save" disabled={isFormValid} />
                                </form>
                            </div>
                        </div>
                        <ToastContainer />
                    </>)
            }
        </>
    );


}



export default Setting;