export const addProductFields = [
    {
        labelText: "Product Name",
        labelFor: "productName",
        id: "productName",
        name: "productName",
        type: "text",
        autoComplete: "productName",
        placeholder: "Product Name",
    },
    {
        labelText: "Vendor Name",
        labelFor: "vendorname",
        id: "vendorname",
        name: "vendorname",
        type: "text",
        autoComplete: "vendorname",
        placeholder: "Vendor Name",
    },
    {
        labelText: "Product Type",
        labelFor: "producttype",
        id: "producttype",
        name: "producttype",
        type: "text",
        autoComplete: "producttype",
        placeholder: "Product Type",
    },
    {
        id: "status",
        name: "status",
        type: "select",
        labelText: "Status",
        labelFor: "Status",
        placeholder: "Select Status",
        isRequired: true,
        options: [
            { value: "ACTIVE", label: "Active" },
            { value: "DRAFT", label: "Draft" },
            { value: "ARCHIVED", label: "Archived" }
        ],
    },
    {
        labelText: "Tags",
        labelFor: "tags",
        id: "tags",
        name: "tags",
        type: "text",
        autoComplete: "tags",
        placeholder: "Tags",
        info: "enter multiple tags comma (,) separated"
    },
    {
        labelText: "SKU",
        labelFor: "sku",
        id: "sku",
        name: "sku",
        type: "text",
        autoComplete: "sku",
        placeholder: "SKU",
    },
    // {
    //     labelText: "Minimum Qty",
    //     labelFor: "minimumQty",
    //     id: "minimumQty",
    //     name: "minimumQty",
    //     type: "number",
    //     autoComplete: "minimumQty",
    //     placeholder: "Minimum Qty",
    //     inputMode: "numeric",
    // },
    {
        labelText: "Quantity",
        labelFor: "quantity",
        id: "quantity",
        name: "quantity",
        type: "number",
        autoComplete: "quantity",
        placeholder: "Quantity",
        inputMode: "numeric",
    },
    {
        labelText: "Price",
        labelFor: "price",
        id: "price",
        labelFor: "price",
        name: "price",
        type: "number",
        step: "0.01",
        min: "0",
        autoComplete: "price",
        placeholder: "Price",
        inputMode: "numeric",
    },
    {
        labelText: "Compare At Price",
        labelFor: "Compare At Price",
        id: "compareAtPrice",
        name: "compareAtPrice",
        type: "number",
        step: "0.01",
        min: "0",
        autoComplete: "compareAtPrice",
        placeholder: "Compare At Price",
        inputMode: "numeric",
    },

];

export const defaultVariantFields = [
    {
        labelText: "Variant Name",
        labelFor: "variantname",
        id: "variantname",
        name: "variantname",
        type: "text",
        autoComplete: "variantname",
        placeholder: "Variants Name",
        isReadonly: true,
        value: "Default title"
    },
    {
        labelText: "Price",
        labelFor: "price",
        id: "variantprice",
        labelFor: "variantprice",
        name: "variantprice",
        type: "number",
        step: "0.01",
        min: "0",
        autoComplete: "variantprice",
        placeholder: "Price",
        inputMode: "numeric",
    },
    {
        labelText: "Quantity",
        labelFor: "quantity",
        id: "variantquantity",
        name: "variantquantity",
        type: "number",
        autoComplete: "quantity",
        placeholder: "variantquantity",
        inputMode: "numeric",
    },
    {
        labelText: "SKU",
        labelFor: "sku",
        id: "variantsku",
        name: "variantsku",
        type: "text",
        autoComplete: "variantsku",
        placeholder: "SKU",
    }
]

export const addProductOptionsFields = [
    {
        id: "options",
        name: "options",
        type: "select",
        labelText: "options",
        labelFor: "options",
        placeholder: "Select Options",
        isRequired: true,
        options: [
            { value: "choose_option", label: "Choose option" },
            { value: "size", label: "Size" },
            { value: "color", label: "Color" },
            { value: "material", label: "Material" },
            { value: "style", label: "Style" },
        ],
    },
    {
        id: "optionsValue",
        name: "optionsValue",
        type: "text",
        labelText: "optionsValue",
        labelFor: "optionsValue",
        placeholder: "Enter Option Value",
        isRequired: true,
    },
]

export const addProductVariantsFields = [
    {
        labelText: "option1name",
        labelFor: "option1name",
        id: "option1name",
        name: "option1name",
        type: "text",
        autoComplete: "option1name",
        placeholder: "enter name",
        isReadonly: true
    },
    {
        labelText: "option1value",
        labelFor: "option1value",
        id: "option1value",
        name: "option1value",
        type: "text",
        autoComplete: "option1value",
        placeholder: "enter value",
        isReadonly: true
    },
    {
        labelText: "option2name",
        labelFor: "option2name",
        id: "option2name",
        name: "option2name",
        type: "text",
        autoComplete: "option2name",
        placeholder: "enter name",
        isReadonly: true
    },
    {
        labelText: "option2value",
        labelFor: "option2value",
        id: "option2value",
        name: "option2value",
        type: "text",
        autoComplete: "option2value",
        placeholder: "enter value",
        isReadonly: true
    },
    {
        labelText: "option3name",
        labelFor: "option3name",
        id: "option3name",
        name: "option3name",
        type: "text",
        autoComplete: "option3name",
        placeholder: "enter name",
        isReadonly: true
    },
    {
        labelText: "option3value",
        labelFor: "option3value",
        id: "option3value",
        name: "option3value",
        type: "text",
        autoComplete: "option3value",
        placeholder: "enter value",
        isReadonly: true
    },
    // {
    //     labelText: "Variant Name",
    //     labelFor: "variantname",
    //     id: "variantname",
    //     name: "variantname",
    //     type: "text",
    //     autoComplete: "variantname",
    //     placeholder: "Variants Name",
    //     isReadonly: true
    // },
    {
        labelText: "Price",
        labelFor: "price",
        id: "variantprice",
        labelFor: "variantprice",
        name: "variantprice",
        type: "number",
        step: "0.01",
        min: "0",
        autoComplete: "variantprice",
        placeholder: "Price",
        inputMode: "numeric",
    },
    {
        labelText: "Quantity",
        labelFor: "quantity",
        id: "variantquantity",
        name: "variantquantity",
        type: "number",
        autoComplete: "quantity",
        placeholder: "variantquantity",
        inputMode: "numeric",
    },
    {
        labelText: "SKU",
        labelFor: "sku",
        id: "variantsku",
        name: "variantsku",
        type: "text",
        autoComplete: "variantsku",
        placeholder: "SKU",
    },
]