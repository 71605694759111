import React, { useState, useEffect } from 'react';
import {ProductFields} from "../../utils/productFileds";
// import { productFilterFields, productDataFields, shopifyProductActionFields, perPages } from "../../utils/productFileds";
import { vendorData, fetchShopifyProductData, importShopifyProducts, deleteProducts, updateProductVendor } from '../../utils/api';
import Table from '../../components/datalist/Table';
import Pagination from '../../components/datalist/Pagination';
import PerPage from '../../components/datalist/PerPage';
import Action from '../../components/datalist/Action';
import CartDrawer from '../../contexts/CartDrawer';
import CustomFilter from '../../components/datalist/CustomFilter';
import FilterBadge from '../../components/datalist/FilterBadge';
import Breadcrumb from '../../contexts/Breadcrumb';
import { ToastContainer, toast } from 'react-toastify';
import SelectOptions from '../../contexts/SelectOptions';
import Modal from '../../contexts/Modal';


const GetQuery = (query) => {
    let queryString = '';
    for (const key in query) {
        let value = (query[key].includes('&')) ? query[key].replace("&", "%26") : query[key];
        if (query[key] !== '') queryString += `&${key}=${value}`;
    }
    return queryString;
}

const ProductsList = () => {
    const {productFilterFields, productDataFields, shopifyProductActionFields, perPages} = ProductFields();
    
    const [data, setData] = useState([]);
    const [page, setPage] = useState(1);
    const [error, setError] = useState(null);
    const [size, setSize] = useState(50);
    const [count, setCount] = useState(null);
    const [selectedAction, setSelectedAction] = useState("");
    const [selectedIndex, setSelectedIndex] = useState(null);
    const [showCart, setShowCart] = useState(false);
    const [search, setSearch] = useState(null);
    const [filterQuery, setFilterQuery] = useState({});
    const [statusState, setStatusState] = useState({
        isUpdating: false,
        updateMessage: "",
        isSuccess: false,
        isError: false,
    });
    const [importSuccess, setImportSuccess] = useState(null);
    const [bulkAction, setBulkAction] = useState(false);
    const [showLoader, setShowLoader] = useState(true);
    const [bulkOperationData, setBulkOperationData] = useState([]);
    const [bulkActionError, setBulkActionError] = useState(false);
    const [vendors, setVendors] = useState([]);
    const [vendorError, setVendorError] = useState('');
    const [modalOpen, setModalOpen] = useState(false);
    const [vendorListVisible, setVendorListVisible] = useState(true);
    
    let debounceTimeout;
    const delayRange = 700;

    const debounce = (func) => {
        clearTimeout(debounceTimeout);
        debounceTimeout = setTimeout(func, delayRange);
    };

    //load the data automatically
    useEffect(() => {
        let queryString = `page=${page}&perPage=${size}&vid=null`;
        if (search) {
            queryString += `&query=${search}`;
        }

        if (Object.keys(queryString).length > 0) {
            queryString += GetQuery(filterQuery);
        }
        debounce(() => handleFetchData(queryString));
        return () => {
            clearTimeout(debounceTimeout);
        };
    }, [page, size, search, filterQuery, importSuccess, bulkAction]);

    const handleImportingProduct = async () => {
        try {
            const response = await importShopifyProducts();
            setStatusState((prevState) => ({
                ...prevState,
                isSuccess: false,
                isUpdating: true,
                updateMessage: "Importing...",
            }));

            if (response.data.success === true) {
                setTimeout(() => {
                    setStatusState((prevState) => ({
                        ...prevState,
                        isSuccess: true,
                        isUpdating: false,
                        updateMessage: "Imported",
                    }));
                }, 1200);
                setImportSuccess(true);
                setTimeout(() => {
                    setStatusState(() => ({
                        isUpdating: false,
                        updateMessage: "",
                        isSuccess: false,
                        isError: false,
                    }));
                }, 3400);
            }
            else {
                setStatusState((prevState) => ({
                    ...prevState,
                    isError: true,
                    isUpdating: false,
                    updateMessage: "Importing failed",
                }));
            }
        } catch (error) {
            setStatusState((prevState) => ({
                ...prevState,
                isError: true,
                isUpdating: false,
                updateMessage: "Importing failed",
            }));
        }
    }


    const handleFetchData = async (queryString) => {
        try {
            setShowLoader(true);
            const response = await fetchShopifyProductData(queryString, setData);
            setCount(response.data.count)
            setData(response.data.products);
            setShowLoader(false);
            setError(response.error);
        } catch (error) {
            setError(error);
        }
    };

    const handlePageChange = (e) => {
        setPage(e.target.value);
    }


    const onRemove = (key) => {
        const updatedQuery = { ...filterQuery };
        delete updatedQuery[key];
        setFilterQuery(updatedQuery);
    }

    const selectAction = (e) => {
        setSelectedAction(e.target.value);
    }

    const handleAction = async (e) => {
        if (selectedAction == 'delete_product') {
            const products = selectedIndex.map((ele) => { return { mid: ele }; });
            try {
                const response = await deleteProducts({ products });
                setBulkAction(!bulkAction);
                toast.success("Products deleted", {
                    position: toast.POSITION.TOP_CENTER
                });
            }
            catch (err) {
                toast.error("Not Updated", {
                    position: toast.POSITION.TOP_CENTER
                });
            }
        }
        else if(selectedAction === "assign_to_vendor"){
            setModalOpen(true);
        }
    }

    const handleNext = () => {
        (page >= 1 && page < (Math.ceil(count / size))) ? setPage(page + 1) : setPage(page);
    }

    const handleChangePage = (e) => {
        setSize(e.target.value);
    }

    const handlePrev = () => {
        (page >= 2) ? setPage(page - 1) : setPage(page);
    }

    useEffect(() =>{
        handleFetchVendors();
    }, [modalOpen]);

    const handleOpenModal = () => {
        setModalOpen(true);
    };
    
    const handleCloseModal = () => {
        setModalOpen(false);
    };

    const handleFetchVendors = async (queryString) => {
        try {
          const response = await vendorData(queryString);
          if (response.data && response.data) {
            setVendors(response.data);
            setVendorError('');
          } else {
            setVendorError('Failed to fetch Vendors');
            setShowLoader(false);
          }
        } catch (error) {
          setVendorError('Failed to fetch Vendors', error);
        }
      }

    const selectVendor = async (vendorId) => {
        const pricePayload = {
          vendor_id: vendorId,
          products: selectedIndex
        }
        try {
          const response = await updateProductVendor(pricePayload);
          if (response.data.success === true) {
            setBulkAction(!bulkAction);
            handleCloseModal();
            return toast.success("Product(s) assigned to vendor", {
              position: toast.POSITION.TOP_CENTER
            });
          }
          else {
            return toast.error(response.error, {
              position: toast.POSITION.TOP_CENTER
            });
          }
        } catch (error) {
          toast.error("Not Updated", {
            position: toast.POSITION.TOP_CENTER
          });
        }
      }

    return (
        <>
            <div className="flex flex-col items-start justify-between pb-6 space-y-4 border-b lg:items-center lg:space-y-0 lg:flex-row">
                <h1 className="text-2xl font-semibold whitespace-nowrap">Products List</h1>
                {/* <Breadcrumb /> */}
            </div>

            <Table columns={productDataFields} setPage={setPage} filterFields={productFilterFields} setSize={setSize} perPages={perPages} shopifyActionFields={shopifyProductActionFields} setSelectedIndex={setSelectedIndex} data={data} selectRow="true" dimension={{ height: '700px', width: '' }} count={count} showLoader={showLoader}>
                <div className='flex flex-wrap items-center mt-6 flex flex-row justify-between border-b border-gray-300 mb-6'>
                    <div className='flex-grow-0 flex-shrink-0 w-full max-w-4xl'>
                        <div className='my-3'>
                            <label htmlFor="default-search" className="mb-2 text-sm font-medium text-gray-900 sr-only dark:text-white">Search Your Products ... </label>
                            <div className="relative">
                                <div className="absolute inset-y-0 left-0 flex items-center pl-3 pointer-events-none">
                                    <svg aria-hidden="true" className="w-5 h-5 text-gray-500 dark:text-gray-400" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path stroke-linecap="round" stroke-linejoin="round" strokeWidth="2" d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z"></path></svg>
                                </div>
                                <input
                                    value={search || ""}
                                    onChange={e => {
                                        setSearch(e.target.value);
                                        //onChange(e.target.value);
                                    }}
                                    className='min-w-[50%] block w-full p-4 pl-10 text-sm text-gray-900 border border-gray-300 rounded-lg bg-gray-50 focus:outline-none focus:ring-purple-500 focus:border-purple-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-purple-500 dark:focus:border-purple-500'
                                    type="search"
                                    placeholder="Search products by title, SKU or tag"
                                />
                                {productFilterFields ? (
                                    <>
                                        {/* <input type="search" id="default-search" className="min-w-[50%] block w-full p-4 pl-10 text-sm text-gray-900 border border-gray-300 rounded-lg bg-gray-50 focus:ring-blue-500 focus:border-blue-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500" placeholder="Search Mockups, Logos..." /> */}
                                        <button className="flex gap-2 text-white absolute right-2.5 bottom-1.5 py-2 px-5 border border-transparent text-sm font-medium rounded-md text-white bg-purple-600 hover:bg-purple-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-purple-500" onClick={() => setShowCart(!showCart)}>
                                            <span><svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth={2} ><polygon points="22 3 2 3 10 12.46 10 19 14 21 14 12.46 22 3"></polygon></svg></span>
                                            <span>Filter</span>
                                        </button>

                                        {showCart && <CartDrawer onClose={() => setShowCart(false)} columns={productDataFields} data={data} >
                                            <CustomFilter setShowCart={setShowCart} fields={productFilterFields} filterQuery={filterQuery} setFilterQuery={setFilterQuery} />
                                        </CartDrawer>}
                                    </>) : ""}
                            </div>
                        </div>
                    </div>
                    <div className='flex-grow-0 flex-shrink-0 min-w-150 '>
                        <div className='flex'>
                            {perPages && perPages.length > 0 && <PerPage changePage={handleChangePage} perPages={perPages} />}
                        </div>
                    </div>
                </div>

                <div className="flex flex-row justify-between mb-6">
                    <div className="flex items-center gap-2 ">
                        { shopifyProductActionFields && shopifyProductActionFields.length > 0 && 
                            <Action selectedIndex={selectedIndex} 
                                actionFields={shopifyProductActionFields} 
                                handleAction={handleAction} 
                                selectAction={selectAction} 
                                bulkOperationData={bulkOperationData}
                            />}
                    </div>
                    
                    <Modal isOpen={modalOpen} onClose={handleCloseModal} heading={"Choose vendor"} type="vendor" vendorListVisible={vendorListVisible}>
                        <SelectOptions vendorsData={vendors} vendorError={vendorError} selectVendor={selectVendor} type="modal" setVendorListVisible={setVendorListVisible}/>
                    </Modal>
                    <div className="flex items-start min-w-450 items-center">
                        <div className="mr-2">
                            <button
                                onClick={handleImportingProduct}
                                disabled={statusState.isUpdating}
                                className={`capitalize flex items-center justify-center px-4 py-2 border border-transparent text-sm font-medium rounded-lg text-white ${statusState.isSuccess ? "bg-green-500" : statusState.isError ? "bg-red-500" : "bg-purple-600 hover:bg-purple-700"
                                    }`}
                            >
                                {statusState.isUpdating ? (
                                    <svg className="animate-spin h-3 w-3 mr-3 text-white" viewBox="0 0 24 24">
                                        <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4" />
                                        <path
                                            className="opacity-75"
                                            fill="currentColor"
                                            d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-1.647zm4-1.647l3 1.647C11.865 17.824 13 15.042 13 12H8a7.962 7.962 0 01-.729 3.291z"
                                        />
                                    </svg>
                                ) : statusState.isSuccess ? (
                                    <svg className="h-3 w-3 mr-2" viewBox="0 0 20 20" fill="currentColor">
                                        <path
                                            fillRule="evenodd"
                                            d="M17.707 5.293a1 1 0 010 1.414l-10 10a1 1 0 01-1.414 0l-5-5a1 1 0 111.414-1.414L7 14.586l9.293-9.293a1 1 0 011.414 0z"
                                            clipRule="evenodd"
                                        />
                                    </svg>
                                ) : statusState.isError ? (
                                    <svg className="h-3 w-3 mr-2" viewBox="0 0 20 20" fill="currentColor">
                                        <path
                                            fillRule="evenodd"
                                            d="M10 18a8 8 0 100-16 8 8 0 000 16zm0-2a6 6 0 100-12 6 6 0 000 12zm0-9a1 1 0 00-1 1v3a1 1 0 102 0V8a1 1 0 00-1-1zm0 6a1 1 0 100 2 1 1 0 000-2z"
                                            clipRule="evenodd"
                                        />
                                    </svg>
                                ) : null}

                                {statusState.isUpdating
                                    ? "Importing..."
                                    : statusState.isSuccess
                                        ? statusState.updateMessage
                                        : statusState.isError
                                            ? statusState.updateMessage
                                            : "Import products"}
                            </button>
                        </div>
                        <Pagination page={page} size={size} handlePageChange={handlePageChange} handleNext={handleNext} count={count} handlePrev={handlePrev} />
                    </div>
                </div>


                <FilterBadge filterQuery={filterQuery} onRemove={onRemove} />

            </Table>
            <ToastContainer />
        </>

    );


}


export default ProductsList;