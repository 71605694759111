const Progress = ({ bulkOperationData, operationName }) => {
    const operation = bulkOperationData[0].action === 'deleteProduct' ? 'Deleting...' : 'Uploading...';
    const percentageCompleted = bulkOperationData[0].percentageCompleted;
    return (
        <>
            {/* <div className="relative pt-1">
                <div className="flex mb-2 items-center justify-between">
                    <div>
                        <span className={`text-xs font-semibold inline-block py-1 px-2 rounded-full ${bulkOperationData[0].action === 'deleteProduct' ? 'text-pink-600 bg-pink-200' : 'text-emerald-600 bg-emerald-200'}`}>
                            {operation}
                        </span>
                    </div>
                    <div className="text-right">
                        <span className={`text-xs font-semibold inline-block ${bulkOperationData[0].action === 'deleteProduct' ? 'text-pink-600' : 'text-emerald-600'}`}>
                            {percentageCompleted}%
                        </span>
                    </div>
                </div>
                <div className={`overflow-hidden h-2 mb-4 text-xs flex rounded ${bulkOperationData[0].action === 'deleteProduct' ? 'bg-pink-200' : 'bg-emerald-200'}`}>
                    <div style={{width:`${percentageCompleted}%`}} className={`shadow-none flex flex-col text-center whitespace-nowrap text-white justify-center ${bulkOperationData[0].action === 'deleteProduct' ? 'bg-pink-500' : 'bg-emerald-500'} bg-pink-500`}></div>
                </div>
            </div> */}
            <div className="ml-4 w-full md:w-2/3 lg:w-1/3" style={{ minWidth: "300px" }}>
                <div className="flex items-center justify-between">
                    <span className={`text-xs font-semibold inline-block py-1 px-2 rounded-full ${bulkOperationData[0].action === 'deleteProduct' ? 'text-pink-600 bg-pink-200' : 'text-emerald-600 bg-emerald-200'}`}>
                        {operation}
                    </span>
                    <span className={`px-2 py-1 bg-teal-50 rounded-lg text-xs ${bulkOperationData[0].action === 'deleteProduct' ? 'text-pink-400' : 'text-teal-400'} font-medium min-w-[46px] text-center`}>{percentageCompleted}%</span>
                </div>
                <div className="w-full bg-slate-300 h-1 mb-2 mt-2">
                    <div className={`${bulkOperationData[0].action === 'deleteProduct' ? 'bg-pink-400' : 'bg-teal-400'} h-1 rounded`} style={{ width: `${percentageCompleted}%` }}></div>
                </div>
            </div>
        </>
    )
}

export default Progress;