import React, { useState, useEffect } from "react";
import { vendorData, } from '../../utils/api';
import Breadcrumb from "../../contexts/Breadcrumb";
import VendorCard from "../../contexts/VendorCard";
import Loader from '../../contexts/Loader';

const VendorList = () => {
    const [showLoader, setShowLoader] = useState(true);
    const [data, setData] = useState([]);
    const [error, setError] = useState(null);
    const [searchQuery, setSearchQuery] = useState("");
    const [showSearchResults, setShowSearchResults] = useState(false);

    useEffect(() => {
        handleFetchData();
    }, []);

    const handleSearch = (event) => {
        const query = event.target.value;
        setSearchQuery(query);

        // Check if the query has a value and update the state accordingly
        setShowSearchResults(query.length > 0);
    };

    const handleFetchData = async (queryString) => {
        try {
            const response = await vendorData(queryString);
            setData(response.data);
            setError(response.error);
            setShowLoader(false);
        } catch (error) {
            setError(error);
            setShowLoader(false);
        }
    };




    const filteredData = data && data.filter((vendor) => {
        return vendor.email && vendor.email.toLowerCase().includes(searchQuery.toLowerCase()) || vendor.username && vendor.username.toLowerCase().includes(searchQuery.toLowerCase());
    });

    return (
        <>
            {showLoader ? (
                <Loader setShowLoader={setShowLoader} />
            ) : (
                <>
                    <div className="border-b pb-6">
                        <div className="flex flex-col items-start justify-between pb-6 space-y-4 lg:items-center lg:space-y-0 lg:flex-row">
                            <h1 className="text-2xl font-semibold whitespace-nowrap">
                                Vendor List
                            </h1>
                            <Breadcrumb />
                        </div>
                    </div>
                    <div className="flex items-center justify-between pb-4 pt-4 pr-4 mb-4">
                        <div className="items-center hidden  space-x-2 md:flex-1 md:flex " >
                            <span className="w-5/6">
                                <input
                                    type="text"
                                    value={searchQuery}
                                    onChange={handleSearch}
                                    placeholder="Search vendors..."
                                    className="w-5/6 rounded-xl border p-3 text-gray-500 px-4 py-2 mb-4 border border-gray-300 rounded-md focus:outline-none focus:ring-purple-500 focus:border-purple-500"
                                />
                            </span>
                        </div>
                    </div>
                    <div className="flex flex-wrap my-4 overflow-y-auto hover:overflow-y-auto">
                        {showSearchResults ? (
                            filteredData && filteredData.length > 0 ? (
                                filteredData.map((vendor) => (
                                    <VendorCard vendor={vendor} key={vendor.id} />
                                ))
                            ) : (
                                <h4>No matching vendors found</h4>
                            )
                        ) : (
                            <>
                                {data && data.length > 0 ? (
                                    data.map((vendor) => (
                                        <VendorCard vendor={vendor} key={vendor.id} />
                                    ))
                                ) : (
                                    <h4>No Vendors Registered yet</h4>
                                )}
                            </>
                        )}
                    </div>
                    {error && (
                        <div className="bg-red-100 border border-red-200 text-red-700 px-2 py-2 rounded relative" role="alert">
                            <span className="block sm:inline text-xs">{error}</span>
                        </div>
                    )}
                </>
            )}
        </>
    );
};

export default VendorList;
