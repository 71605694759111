import React, { useState } from 'react';

const AccountCreatedDetails = ({ email, password }) => {
    const [copied, setCopied] = useState(false);

    const handleCopy = () => {
        navigator.clipboard.writeText(`${email}, ${password}`);
        setCopied(true);
    };

    return (
        <div className="flex flex-col items-start space-y-4">
            <h2 className="text-xl font-semibold">Vendor Details</h2>
            <div className="flex items-center space-x-2">
                <p className="font-medium">Email:</p>
                <p>{email}</p>
            </div>
            <div className="flex items-center space-x-2">
                <p className="font-medium">Password:</p>
                <p>{password}</p>
            </div>
            <button
                onClick={handleCopy}
                className="flex items-center space-x-1 text-blue-500 hover:text-blue-700"
            >
                <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="h-5 w-5"
                    viewBox="0 0 20 20"
                    fill="currentColor"
                >
                    <path
                        fillRule="evenodd"
                        d="M10 2a8 8 0 100 16 8 8 0 000-16zM6 9a1 1 0 011-1h6a1 1 0 010 2H7a1 1 0 01-1-1zm1 3a1 1 0 100 2h4a1 1 0 100-2H7z"
                        clipRule="evenodd"
                    />
                </svg>
                <span>{copied ? 'Copied!' : 'Copy Email and Password'}</span>
            </button>
        </div>
    );
};

export default AccountCreatedDetails;
