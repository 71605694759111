import React from 'react';
import { useNavigate, Link } from 'react-router-dom';
import RandomAvatar from '../contexts/RandomAvatar';

const VendorCard = ({ vendor }) => {
    const navigate = useNavigate();

    const handleViewOrders = () => {
        // Redirect to the vendor's order list page
        navigate(`/vendororder/${vendor.user_id}`);
        // window.location.href = `/product/${rowData.mid}`;
    };

    return (
        <div className="w-full lg:w-1/3 p-2" key={vendor?.user_id}>
            <div className='transition-shadow border rounded-lg shadow-sm hover:shadow-lg rounded-lg shadow-md flex flex-col items-center bg-gray-50'>
                <Link to={`/vendordetail/${vendor?.user_id}`} className="p-6">
                    <div className="flex items-center mb-4 flex flex-col items-center text-center">
                        <RandomAvatar className="max-h-full max-w-full object-cover shadow-sm dark:border-transparent h-14 w-14 rounded-full" key={vendor.username} />
                        <div className="ml-4">
                            <h3 className="text-lg font-semibold">{vendor?.username}</h3>
                            <p className="text-gray-500 break-all">{vendor?.email}</p>
                        </div>
                    </div>
                    <div className="flex items-center justify-between mb-2 flex-col items-center">
                        <span
                            className={`mb-4 px-2 py-1 text-sm font-semibold rounded ${vendor?.status === false
                                ? 'bg-yellow-500 text-white'
                                : 'bg-green-500 text-white'
                                }`}
                        >
                            {vendor?.status === false ? "InActive" : "Active"}
                        </span>
                        <div className="flex">
                            <span className="px-2 py-1 mr-2 text-xs font-semibold bg-blue-200 text-blue-800 rounded">
                                Products: {vendor?.products}
                            </span>
                            <span className="px-2 py-1 text-xs font-semibold bg-purple-800 text-white rounded">
                                Orders: {vendor?.orders}
                            </span>
                            <span className="px-2 py-1 ml-2 text-xs font-semibold bg-yellow-200 text-yellow-800 rounded">
                                Pending: {vendor?.pendingOrders ? vendor.pendingOrders : 0}
                            </span>
                        </div>
                    </div>
                </Link>
                <div className="flex justify-between w-full mt-auto">
                    <Link to={`/vendororder/${vendor?.user_id}`} className="text-center flex-1 px-4 py-3 bg-purple-800 text-white hover:bg-purple-600 focus:outline-none focus:ring-2 focus:ring-purple-500">
                        View Orders
                    </Link>
                    <Link to={`/vendorproduct/${vendor?.user_id}`} className="text-center flex-1 px-4 py-3 bg-blue-200 text-blue-800 hover:bg-blue-300 focus:outline-none focus:ring-2 focus:ring-blue-500">
                        View Products
                    </Link>
                </div>
            </div>
        </div>


    );
};

export default VendorCard;
