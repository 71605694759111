import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router";
import { createProduct } from '../../utils/api';
import Loader from '../../contexts/Loader';
import Breadcrumb from "../../contexts/Breadcrumb";
import { addProductFields, addProductOptionsFields, addProductVariantsFields, defaultVariantFields } from "../../utils/addProductFields";
import Input from "../../contexts/Input";
import FormAction from "../../contexts/FormAction";
import { ProductImageUpload } from "../../contexts/ProductImageUpload";
import CGMDescription from "../../components/quill/CGMDescription";
import { addShopifyProduct } from "../../utils/addShopifyProduct";
import AddVariants from "../../contexts/AddVariants";
import Modal from '../../contexts/Modal';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';


const initialRowData = {
    option1Name: '',
    option1Value: '',
    option2Name: '',
    option2Value: '',
    option3Name: '',
    option3Value: '',
    variantName: '',
    price: '',
    compareAtPrice: '',
    quantity: '',
    sku: '',
};

const AddProduct = () => {
    const [showLoader, setShowLoader] = useState(false);
    const [rows, setRows] = useState([initialRowData]);
    const [productImages, setProductImages] = useState([]);
    const [richText, setRichText] = useState('');
    const [variant, setVariant] = useState([]);
    const [hiddenFields, setHiddenFields] = useState(false);
    const requiredFields = ['productName', 'vendorname', 'producttype', 'sku', 'quantity', 'price', 'status'];
    const varinatsRequiredFields = ['productName', 'vendorname', 'producttype', 'status'];
    const [modalOpen, setModalOpen] = useState(false);
    const navigate = useNavigate();

    const fields = addProductFields;
    let fieldsState = {};
    fields.forEach(field => fieldsState[field.id] = '');

    let defaultVariantField = defaultVariantFields;
    let defaultVariantFieldsState = {};
    defaultVariantField.forEach(field => defaultVariantFieldsState[field.id] = '');

    const [addproduct, setAddProduct] = useState(fieldsState);
    const [error, setError] = useState(null);

    const handleChange = (e) => {
        setError('');
        setAddProduct({ ...addproduct, [e.target.id]: e.target.value });
        setError(null);
    };

    const handleOpenModal = () => {
        setModalOpen(true);
        setHiddenFields(true);
    };

    const handleCloseModal = () => {
        if (variant && variant.length > 0) {
            setHiddenFields(true);
        }
        else {
            setHiddenFields(false);
        }
        setModalOpen(false);
    };

    const closeModal = (value) => {
        if (variant && variant.length > 0) {
            setHiddenFields(true);
        }
        else {
            setHiddenFields(false);
        }
        setModalOpen(value);
    }


    const handleSubmit = (e) => {
        e.preventDefault();
        const formData = new FormData();
        for (let i = 0; i < productImages.length; i++) {
            formData.append("files", productImages[i]);
        }
        const action = e.nativeEvent.submitter.innerHTML.toLowerCase();
        if (action.includes('cancel')) {
            navigate(-1, { replace: true });
        } else {
            for (const field of hiddenFields ? varinatsRequiredFields : requiredFields) {
                if (!addproduct[field]) {
                    return setError(`${field.replace('_', ' ')} field is required.`);
                }
            }
            if (!richText) {
                return setError(`Please input the description field.`);
            }
            if (productImages && productImages.length === 0) {
                return setError(`Please select at least one image.`);
            }
            handleImageUpload(productImages);
            // console.log(addproduct, formData, richText, variant);
            let shopifyProduct = addShopifyProduct(addproduct, formData, richText, variant);
            handleCreateProduct(shopifyProduct);
        }
    };

    const handleImageUpload = (uploadedImages) => {
        setProductImages(uploadedImages);
    };

    const handleRemoveImage = (index) => {
        const newImages = [...productImages];
        newImages.splice(index, 1);
        setProductImages(newImages);
    }

    const handleTextChange = (value) => {
        setRichText(value);
    };

    const variantList = (value) => {
        setVariant(value);
    };

    useEffect(() => {
        setHiddenFields(Array.isArray(variant) && variant.length > 0);
    }, [variant]);

    const handleRemoveRow = () => {
        setVariant([]);
        setHiddenFields(false);
    };

    const handleCreateProduct = async (data) => {
        try {
            setShowLoader(true);
            // const response = {data:{success: false}};
            const response = await createProduct(data);
            if (response.data.success === true) {
                toast.success("Product Created", {
                    position: toast.POSITION.TOP_CENTER,
                    autoClose: 2500
                });
                setAddProduct(fieldsState);
                setProductImages([]);
                setRichText('');
                setVariant([]);
                // window.location.href = `/product/notapproved/${response.data.product._id}`;
            }
            else if(response.data.success === false && response.data.message === "Invalid MIME type!"){
                toast.error("Invalid MIME type!", {
                    position: toast.POSITION.TOP_CENTER
                });
            }
            else {
                toast.error("Error", {
                    position: toast.POSITION.TOP_CENTER
                });
            }
        } catch (error) {
            toast.error("Error", {
                position: toast.POSITION.TOP_CENTER
            });
        }
        finally {
            setShowLoader(false);
        }
    };

    return (
        <>
            {showLoader ?
                (<Loader setShowLoader={setShowLoader} time='10000' />) :
                (<>
                    <div className='border-b pb-6'>
                        <div className="flex flex-col items-start justify-between pb-1 space-y-4 lg:items-center lg:space-y-0 lg:flex-row">
                            <h1 className="text-2xl font-semibold whitespace-nowrap">Product Add</h1>
                            <Breadcrumb />
                        </div>
                        <p className="text-xs pb-4 text-gray-800">Create New product</p>
                    </div>
                    <div className="rounded-md border p-4 m-2 bg-gray-50 mt-4">
                        <form className="space-y-6 p-3" onSubmit={handleSubmit}>
                            <div className="flex flex-wrap -mx-2 overflow-y-auto hover:overflow-y-auto">
                                {fields.filter((field) => hiddenFields ? hiddenFields && !['sku', 'quantity', 'price', 'minimumQty', 'compareAtPrice'].includes(field.id) : !hiddenFields).map((field) =>
                                    < div className="w-full lg:w-1/4 px-2" key={field.id} >
                                        <label className="block -mb-2">
                                            <span className="text-md font-medium text-slate-700 mb-0">{field.placeholder}</span>
                                            <span className="text-xs font-medium text-gray-400 ml-3">{field.info}</span>
                                        </label>
                                        <Input
                                            key={field.id}
                                            handleChange={handleChange}
                                            value={addproduct[field.id] || ''}
                                            labelText={field.labelText || field.placeholder}
                                            labelFor={field.labelFor || field.placeholder}
                                            id={field.id}
                                            name={field.name}
                                            type={field.type}
                                            placeholder={field.placeholder}
                                            options={field.options}
                                            pattern={field.pattern}
                                            inputMode={field.inputMode}
                                        />
                                    </div>
                                )}
                            </div>
                            <div className="mr-2 flex gap-4">
                                <a onClick={handleOpenModal} className="cursor-pointer inline-flex items-center justify-center px-4 py-1 space-x-1 bg-pink-500 text-white rounded-md shadow hover:bg-pink-600">{variant && variant.length > 0 ? 'Edit Variants' : 'Add Variants'}</a>
                                <a
                                    className="cursor-pointer text-red-600 hover:text-red-800"
                                    onClick={() => handleRemoveRow()}
                                >
                                    <div className="icon-trash">
                                        <div className="trash-lid" style={{ backgroundColor: "#e01832" }}></div>
                                        <div className="trash-container" style={{ backgroundColor: "#e01832" }}></div>
                                        <div className="trash-line-1"></div>
                                        <div className="trash-line-2"></div>
                                        <div className="trash-line-3"></div>
                                    </div>
                                </a>
                            </div>
                            {variant && variant.length > 0 && (
                                <table className="w-full border">
                                    <thead>
                                        <tr className="bg-gray-200">
                                            <th className="border px-4 py-2 text-xs w-1/12">Option1 Name</th>
                                            <th className="border px-4 py-2 text-xs w-1/12">Option1 Value</th>
                                            <th className="border px-4 py-2 text-xs w-1/12">Option2 Name</th>
                                            <th className="border px-4 py-2 text-xs w-1/12">Option2 Value</th>
                                            <th className="border px-4 py-2 text-xs w-1/12">Option3 Name</th>
                                            <th className="border px-4 py-2 text-xs w-1/12">Option3 Value</th>
                                            <th className="border px-4 py-2 text-xs w-2/12">Variant Name</th>
                                            <th className="border px-4 py-2 text-xs w-1/12">Price</th>
                                            <th className="border px-4 py-2 text-xs w-1/12">Compare At Price</th>
                                            <th className="border px-4 py-2 text-xs w-1/12">Quantity</th>
                                            <th className="border px-4 py-2 text-xs w-1/12">SKU</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {variant.map((row, index) => {
                                            return (
                                                <tr key={index} className="border">
                                                    <td className="border text-center p-2">{row.option1Name}</td>
                                                    <td className="border text-center p-2">{row.option1Value}</td>
                                                    <td className="border text-center p-2">{row.option2Name}</td>
                                                    <td className="border text-center p-2">{row.option2Value}</td>
                                                    <td className="border text-center p-2">{row.option3Name}</td>
                                                    <td className="border text-center p-2">{row.option3Value}</td>
                                                    <td className="border text-center p-2">{row.variantName}</td>
                                                    <td className="border text-center p-2">{row.price}</td>
                                                    <td className="border text-center p-2">{row.compareAtPrice}</td>
                                                    <td className="border text-center p-2">{row.quantity}</td>
                                                    <td className="border text-center p-2">{row.sku}</td>
                                                </tr>
                                            );
                                        })}
                                    </tbody>
                                </table>)
                            }
                            <div className="w-full">
                                <label className="block mb-2">
                                    <span className="text-md font-medium text-slate-700 mb-0">Product Description</span>
                                </label>
                                <CGMDescription richText={richText} onChange={handleTextChange} readOnly={false} />
                            </div>
                            <ProductImageUpload onUpload={handleImageUpload} />
                            {error &&
                                <div className="bg-red-100 border border-red-200 text-red-700 px-2 py-2 rounded relative" role="alert">
                                    <span className="block sm:inline text-xs">{error}</span>
                                </div>
                            }
                            <div className="flex gap-4 w-32">
                                <FormAction text="Save" data-action="save" />
                                <FormAction text="Cancel" classes="bg-red-500 hover:bg-red-700" data-action="cancel" />
                            </div>
                        </form>
                    </div>
                </>)
            }
            <Modal isOpen={modalOpen} onClose={handleCloseModal} heading={"Add Variants"} size="max-w-7xl">
                <AddVariants closeModal={closeModal} variantList={variantList} variantData={variant} rows={rows} setRows={setRows} initialRowData={initialRowData} setError={setError}/>
            </Modal>
            <ToastContainer />
        </>
    );
};
export default AddProduct;
