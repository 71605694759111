import React, { useState } from 'react';
import FormAction from '../contexts/FormAction';

function PriceUpdateForm({ updatePrice }) {
    const [priceType, setPriceType] = useState('');
    const [flatPrice, setFlatPrice] = useState('');
    const [percentage, setPercentage] = useState('');
    const [inputError, setInputError] = useState({error: false, message: ''});

    const handlePriceTypeChange = (event) => {
        setPriceType(event.target.value);
        setFlatPrice('');
        setPercentage('');
    };

    const handleSellPriceChange = (event) => {
        const valuePercentage = event.target.value;
        const numericValue = valuePercentage.replace(/[^0-9]/g, ''); 
        if(event.key === '+' || event.key === '-'){
            event.preventDefault();
            return null;
        }
        const isValidPercentage = /^\d+$/.test(valuePercentage);
        if (isValidPercentage) {
            setPercentage('');
            setFlatPrice(event.target.value);
        }
        else {
            event.target.value = numericValue;
            setFlatPrice(event.target.value);
            setPercentage('');
        }

        if(+numericValue > 0){
            setInputError({error: false, message: ''});
        }
        else if(+numericValue === 0){
            setInputError({error: true, message: 'Price should be greater than 0'});
        }
        else{
            setInputError({error: false, message: ''});
        }
    };

    const handlePercentageChange = (event) => {
        const valuePercentage = event.target.value;
        const numericValue = valuePercentage.replace(/[^0-9]/g, ''); 
        if(event.key === '+' || event.key === '-'){
            event.preventDefault();
            return null;
        }
        const isValidPercentage = /^\d+$/.test(valuePercentage);
        if (isValidPercentage) {
            setPercentage(numericValue);
            setFlatPrice('');
        }
        else {
            event.target.value = numericValue;
            setPercentage(numericValue);
            setFlatPrice('');
        }

        if(+numericValue > 0 && +numericValue <= 100){
            setInputError({error: false, message: ''});
        }
        else if(+numericValue > 100){
            setInputError({error: true, message: 'Percentage should be less than 100'});
        }
        else if(+numericValue === 0){
            setInputError({error: true, message: 'Percentage should be greater than 0'});
        }
        else{
            setInputError({error: false, message: ''});
        }
    };
    
    const handleSubmit = (event) => {
        event.preventDefault();
        let formData = {};

        if (priceType === 'flat') {
            formData = {
                type: 'flat',
                value: flatPrice,
            };
        } else if (priceType === 'percentage') {
            formData = {
                type: 'percentage',
                value: percentage,
            };
        }
        updatePrice(formData);
    };

    return (
        <form
            onSubmit={handleSubmit}
            className="w-full bg-gray-50 mt-8 space-y-6 rounded-md shadow-md border p-4 py-6 sticky top-0"
        >
            <div className="-space-y-px">
                <div className="mb-4">
                    <label htmlFor="priceType" className="block text-sm font-medium text-gray-700">
                        Price Update Type:
                    </label>
                    <select
                        id="priceType"
                        name="priceType"
                        required
                        onChange={handlePriceTypeChange}
                        className="mt-3 block w-full py-2 px-3 border border-gray-300 bg-white rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500"
                        value={priceType}
                    >
                        <option value="" disabled>
                            Select price update type
                        </option>
                        <option value="flat">Flat Price</option>
                        <option value="percentage">Percentage</option>
                    </select>
                </div>

                {priceType === 'flat' && (
                    <div id="flatPriceFields" className="">
                        <label htmlFor="sellPrice" className="block text-sm font-medium text-gray-700">
                            Sell Price (Flat):
                        </label>
                        <input
                            type="number"
                            id="sellPrice"
                            name="sellPrice"
                            required
                            className="mt-3 block w-full py-2 px-3 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500"
                            value={flatPrice}
                            onChange={handleSellPriceChange}
                        />
                    </div>
                )}

                {priceType === 'percentage' && (
                    <div id="percentagePriceFields" className="">
                        <label htmlFor="percentage" className="block text-sm font-medium text-gray-700">
                            Percentage(1-100):
                        </label>
                        <div className="flex mt-3 items-center">
                            <input
                                type="number"
                                id="percentage"
                                name="percentage"
                                required
                                className="w-full py-2 px-3 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500"
                                value={percentage}
                                onChange={handlePercentageChange}
                            />
                            <span className="ml-2">
                                <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    viewBox="0 0 24 24"
                                    fill="none"
                                    stroke="currentColor"
                                    strokeWidth="2"
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                    className="feather feather-percent"
                                    style={{ width: '20px' }}
                                >
                                    <path d="M19 5L5 19"></path>
                                    <circle cx="6.5" cy="6.5" r="2.5"></circle>
                                    <circle cx="17.5" cy="17.5" r="2.5"></circle>
                                </svg>
                            </span>
                        </div>
                    </div>
                )}
                {inputError.error && <span className='text-sm text-red-500'>*{inputError.message}</span>}
                <div className="flex justify-end">
                    <FormAction text="Update Price" disabled={inputError.error}/>
                </div>
            </div>
        </form>
    );
}

export default PriceUpdateForm;
