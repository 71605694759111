import React, { useState, useEffect, useRef } from 'react';
// import { productFilterFields, productDataFields, actionFields, perPages, actionFieldsVendor } from "../../utils/productFileds";
import {ProductFields} from "../../utils/productFileds";
import { fetchProductData, exportProductCSV, updateProductCSV, updateProductStatus, updateProductStatusShopify, deleteProducts, getBulkOperations } from '../../utils/api';
import Table from '../../components/datalist/Table';
import Pagination from '../../components/datalist/Pagination';
import PerPage from '../../components/datalist/PerPage';
import Action from '../../components/datalist/Action';
import CartDrawer from '../../contexts/CartDrawer';
import CustomFilter from '../../components/datalist/CustomFilter';
import FilterBadge from '../../components/datalist/FilterBadge';
import Breadcrumb from '../../contexts/Breadcrumb';
import CSVExport from '../../contexts/CSVExport';
import Modal from '../../contexts/Modal';
import Loader from '../../contexts/Loader';
import CSVUpload from '../../contexts/CSVUpload';
import { checkRole } from '../../auth';
import { ToastContainer, toast } from 'react-toastify';
import Progress from '../../components/datalist/Progress';

const GetQuery = (query) => {
  let queryString = '';
  for (const key in query) {
    let value = (query[key].includes('&')) ? query[key].replace("&", "%26") : query[key];
    if (query[key] !== '') queryString += `&${key}=${value}`;
  }
  return queryString;
}
const ProductsList = () => {
  const {productFilterFields, productDataFields, actionFields, perPages, actionFieldsVendor} = ProductFields();

  const [data, setData] = useState([]);
  const [page, setPage] = useState(1);
  const [error, setError] = useState(null);
  const [size, setSize] = useState(50);
  const [count, setCount] = useState(null);
  const [selectedAction, setSelectedAction] = useState("");
  const [selectedIndex, setSelectedIndex] = useState(null);
  const [showCart, setShowCart] = useState(false);
  const [search, setSearch] = useState(null);
  const [filterQuery, setFilterQuery] = useState({});
  const [csvExportStatus, setCsvExportStatus] = useState(null);
  const [showLoader, setShowLoader] = useState(true);
  const [modalOpen, setModalOpen] = useState(false);
  const [csvError, setCsvError] = useState(false);
  const [loader, setLoader] = useState(false);
  const [csvStatusUpdate, setCsvStatusUpdate] = useState(false);
  const [bulkAction, setBulkAction] = useState(false);
  const [bulkOperationData, setBulkOperationData] = useState(false);
  const [bulkActionError, setBulkActionError] = useState(false);
  let [bulkOperationIsCompleted, setBulkOperationIsCompleted] = useState(null);

  const firstUpdate = useRef(true);

  const handleOpenModal = () => {
    setModalOpen(true);
  };
  
  const handleCloseModal = () => {
    setModalOpen(false);
    setCsvStatusUpdate(false);
    setLoader(false);
    setCsvError(false);
  };

  const handleUpload = async (formData) => {
    // handle uploaded file here
    setLoader(true);

    try {
      const response = await updateProductCSV(formData);
      await new Promise((resolve) => setTimeout(resolve, 2000)); // Wait for 2 seconds
      if (response.data.success && response.data.success == true) {
        setLoader(false);
        setCsvStatusUpdate(true);
        setCsvError(false);
      }
    } catch (error) {
      setLoader(false);
      setCsvStatusUpdate(false);
      setCsvError(true);
    }
  };
  let debounceTimeout;
  const delayRange = 700;

  const debounce = (func) => {
    clearTimeout(debounceTimeout);
    debounceTimeout = setTimeout(func, delayRange);
  };

  //load the data automatically
  useEffect(() => {
    let queryString = `page=${page}&perPage=${size}`;
    if (search) {
      queryString += `&query=${search}`;
    }

    if (Object.keys(queryString).length > 0) {
      queryString += GetQuery(filterQuery);
    }
    debounce(() => handleFetchData(queryString));
    return () => {
      clearTimeout(debounceTimeout);
    };
  }, [page, size, search, filterQuery, bulkAction]);
  
  useEffect(()=>{
    if(checkRole()){
      let requestInterval = setInterval(async()=>{
        const bulkOperationResponse = await fetchBulkOperationData();
        if(bulkOperationResponse.data.operations.length === 0){
          if (!firstUpdate.current) {
            let queryString = `page=${page}&perPage=${size}`;
            if (search) {
              queryString += `&query=${search}`;
            }

            if (Object.keys(queryString).length > 0) {
              queryString += GetQuery(filterQuery);
            }
            handleFetchData(queryString);
          }
          else {
            firstUpdate.current = false;
          }
          clearInterval(requestInterval);
        }
      }, 2000);
    }
  }, [bulkAction]);

  const fetchBulkOperationData = async () => {
    let bulkOperationResponse;
    try {
      bulkOperationResponse = await getBulkOperations();
      setBulkOperationData(bulkOperationResponse.data.operations);
      bulkOperationResponse.data.operations.length === 0 ? setBulkOperationIsCompleted(false) : setBulkOperationIsCompleted(true);
    } catch (error) {
      setError(error);
    }
    finally {
      setShowLoader(false);
    }
    return bulkOperationResponse;
  };

  const handleFetchData = async (queryString) => {
    try {
      setShowLoader(true);
      const response = await fetchProductData(queryString, setData);
      if(checkRole()){
        const bulkOperationResponse = await getBulkOperations();
        setBulkOperationData(bulkOperationResponse.data.operations);
      }
      setCount(response.data.count);
      setData(response.data.products);
      setError(response.error);
    } catch (error) {
      setError(error);
    }
    finally {
      setShowLoader(false);
    }
  };

  const handlePageChange = (e) => {
    setPage(e.target.value);
  }


  const onRemove = (key) => {
    const updatedQuery = { ...filterQuery };
    delete updatedQuery[key];
    setFilterQuery(updatedQuery);
  }

  const selectAction = (e) => {      
    e.target.value != "select_option" ? setBulkActionError(false) : setBulkActionError({error: true, message: "Action not selected"});
    setSelectedAction(e.target.value);
  }

  const handleAction = async (e) => {
    if (selectedAction == 'upload_product') {
      const products = selectedIndex.map((ele) => { return { mid: ele }; });
      try {
        const postData = { action: "createProduct", data: products, processedData: []};
        const response = await updateProductStatus(postData);
        setBulkAction(!bulkAction);

        if (response.data.success) {
          toast.dismiss();
          toast.success(`Product(s) uploading started...`, {
            position: toast.POSITION.TOP_CENTER
          });
        }
      }
      catch (err) {
        toast.error("Something went wrong", {
          position: toast.POSITION.TOP_CENTER
        });
      }

    }
    else if (selectedAction == 'delete_product') {
      const products = selectedIndex.map((ele) => { return { mid: ele }; });
      try {
        const postData = { action: "deleteProduct", data: products, processedData: []};
        const response = await deleteProducts(postData);
        setBulkAction(!bulkAction);
        if (response.data.success) {
          toast.dismiss();
          toast.success(`Start deleting product(s)`, {
            position: toast.POSITION.TOP_CENTER
          });
        }

      }
      catch (err) {
        toast.error("Something went wrong", {
          position: toast.POSITION.TOP_CENTER
        });
      }
    }
    if (selectedAction == 'draft') {
      try{
        const products = selectedIndex.map((ele) => { return { mid: ele }; });
        const response = await updateProductStatusShopify({ status: "DRAFT", products});
        setBulkAction(!bulkAction);

        if (response.data.success) {
          toast.dismiss();
          toast.success(`${response.data.updateResponse.message}`, {
            position: toast.POSITION.TOP_CENTER
          });
        }
      }
      catch(err){
        toast.dismiss();
        toast.error("Something went wrong", {
          position: toast.POSITION.TOP_CENTER
        });
      }
    }
    else if(selectedAction === 'active'){
      try{
        const products = selectedIndex.map((ele) => { return { mid: ele }; });
        const response = await updateProductStatusShopify({ status: "ACTIVE", products});
        setBulkAction(!bulkAction);

        if (response.data.success) {
          toast.dismiss();
          toast.success(`${response.data.updateResponse.message}`, {
            position: toast.POSITION.TOP_CENTER
          });
        }
      }
      catch(err){
        toast.dismiss();
        toast.error("Something went wrong", {
          position: toast.POSITION.TOP_CENTER
        });
      }
    }
    else if(selectedAction === 'archived'){
      try{
        const products = selectedIndex.map((ele) => { return { mid: ele }; });
        const response = await updateProductStatusShopify({ status: "ARCHIVED", products});
        setBulkAction(!bulkAction);

        if (response.data.success) {
          toast.dismiss();
          toast.success(`${response.data.updateResponse.message}`, {
            position: toast.POSITION.TOP_CENTER
          });
        }
      }
      catch(err){
        toast.dismiss();
        toast.error("Something went wrong", {
          position: toast.POSITION.TOP_CENTER
        });
      }
    }
    else {
      setBulkActionError({error: true, message: "Action not selected"});
    }
  }

  const handleNext = () => {
    (page >= 1 && page < (Math.ceil(count / size))) ? setPage(+page + 1) : setPage(page);
  }

  const handleChangePage = (e) => {
    setSize(e.target.value);
  }

  const handlePrev = () => {
    (page >= 2) ? setPage(+page - 1) : setPage(page);
  }

  const csvExport = async () => {
    try {
      const response = await exportProductCSV();
      if (response.data.status === 201) {
        setCsvExportStatus(true);
        return true;
      }
      else if(response.data.status === 200){
        setCsvExportStatus(false);
        toast.dismiss();
        toast.error(`No approved product(s) to export`, {
          position: toast.POSITION.TOP_CENTER,
          autoClose: 2000
        });
        return false;
      }
      else {
        setCsvExportStatus(false);
        return false;
      }
    } catch (error) {
      return false;
    }
  }

  return (
    <>

      <div className="flex flex-col items-start justify-between pb-6 space-y-4 border-b lg:items-center lg:space-y-0 lg:flex-row">
        <h1 className="text-2xl font-semibold whitespace-nowrap">Products List</h1>
        {/* <Breadcrumb /> */}
      </div>

      {<Table columns={productDataFields} setPage={setPage} filterFields={productFilterFields} setSize={setSize} perPages={perPages} actionFields={actionFields} setSelectedIndex={setSelectedIndex} data={data} selectRow="true" dimension={{ height: '700px', width: '' }} count={count} size={size} showLoader={showLoader} gridType="products">
        <div className='flex flex-wrap items-center mt-6 flex flex-row justify-between border-b border-gray-300 mb-6'>
          <div className='flex-grow-0 flex-shrink-0 w-full max-w-4xl'>
            <div className='my-3'>
              <label htmlFor="default-search" className="mb-2 text-sm font-medium text-gray-900 sr-only dark:text-white">Search Your Products... </label>
              <div className="relative">
                <div className="absolute inset-y-0 left-0 flex items-center pl-3 pointer-events-none">
                  <svg
                    aria-hidden="true"
                    className="w-5 h-5 text-gray-500 dark:text-gray-400"
                    fill="none"
                    stroke="currentColor"
                    viewBox="0 0 24 24"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth="2"
                      d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z"
                    ></path>
                  </svg>                </div>
                <input
                  value={search || ""}
                  onChange={e => {
                    setSearch(e.target.value);
                    //onChange(e.target.value);
                  }}
                  className='min-w-[50%] block w-full p-4 pl-10 text-sm text-gray-900 border border-gray-300 rounded-lg bg-gray-50 focus:outline-none focus:ring-purple-500 focus:border-purple-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-purple-500 dark:focus:border-purple-500'
                  type="search"
                  placeholder="Search products by title, SKU or tag"
                />
                {productFilterFields ? (
                  <>
                    {/* <input type="search" id="default-search" className="min-w-[50%] block w-full p-4 pl-10 text-sm text-gray-900 border border-gray-300 rounded-lg bg-gray-50 focus:ring-blue-500 focus:border-blue-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500" placeholder="Search Mockups, Logos..." /> */}
                    <button className="flex gap-2 text-white absolute right-2.5 bottom-1.5 py-2 px-5 border border-transparent text-sm font-medium rounded-md text-white bg-purple-600 hover:bg-purple-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-purple-500" onClick={() => setShowCart(!showCart)}>
                      <span><svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth={2} ><polygon points="22 3 2 3 10 12.46 10 19 14 21 14 12.46 22 3"></polygon></svg></span>
                      <span>Filter</span>
                    </button>

                    {showCart && <CartDrawer onClose={() => setShowCart(false)} columns={productDataFields} data={data} >
                      <CustomFilter setShowCart={setShowCart} fields={productFilterFields} filterQuery={filterQuery} setFilterQuery={setFilterQuery} />
                    </CartDrawer>}
                  </>) : ""}
              </div>
            </div>
          </div>
          <div className='flex-grow-0 flex-shrink-0 min-w-150 '>
            <div className='flex'>
              {perPages && perPages.length > 0 && <PerPage changePage={handleChangePage} perPages={perPages} />}
            </div>
          </div>
        </div>

        <div className="flex flex-row justify-between mb-6">
          <div className="flex items-center gap-2 ">
            {checkRole() ?
              actionFields && actionFields.length > 0 && <Action bulkActionError={bulkActionError} bulkOperationData={bulkOperationData} selectedIndex={selectedIndex} actionFields={actionFields} handleAction={handleAction} selectAction={selectAction} /> :
              actionFieldsVendor && actionFieldsVendor.length > 0 && <Action bulkActionError={bulkActionError} bulkOperationData={[]} selectedIndex={selectedIndex} actionFields={actionFieldsVendor} handleAction={handleAction} selectAction={selectAction} role="vendor"/>
            }
            {bulkOperationData && bulkOperationData.length > 0 && <Progress bulkOperationData={bulkOperationData}/>}
          </div>
          <div className="flex items-start min-w-450 items-center">
            {!checkRole() && <div className="mr-2">
              <button onClick={handleOpenModal} disabled={data.length > 0 ? null : true} className={`capitalize flex items-center justify-center px-4 py-2 border border-transparent text-sm font-medium rounded-lg ${data.length === 0 ? 'text-white bg-gray-300 cursor-not-allowed' : 'text-white bg-green-600 hover:bg-green-700'}`}>Update Inventory</button>
            </div>}
            {!checkRole() && <CSVExport csvExport={csvExport} csvExportStatus={csvExportStatus} data={data}/>}
            <Pagination page={page} size={size} handlePageChange={handlePageChange} handleNext={handleNext} count={count} handlePrev={handlePrev} />
          </div>
        </div>
        <Modal isOpen={modalOpen} onClose={handleCloseModal} heading={"INVENTORY UPDATE"}>
          <h2 className="text-lg font-medium mb-4">How to update the product inventory using CSV?</h2>
          <ol className="list-decimal pl-6 mb-6 ">
            <li className="mb-2 text-gray-800">Firstly, you need to export the CSV file which contains the current product data.</li>
            <>
              <li className="mb-2 text-gray-800">Open the CSV file and enter the updated inventory for each product.</li>
              <p className="mb-2 text-purple-800">Note: Don't make any changes in the CSV header and some columns like Id,
                Variant Id, Inventory Variant Id, otherwise inventory can't be uploaded.</p>
            </>
            <li className="mb-2 text-gray-800">Lastly, use the updated csv file and upload it.</li>
          </ol>
          <CSVUpload onUpload={handleUpload} csvStatusUpdate={csvStatusUpdate} loader={loader} csvError={csvError} />
        </Modal>

        <FilterBadge filterQuery={filterQuery} onRemove={onRemove} />

      </Table >}
      <ToastContainer />
    </>

  );


}


export default ProductsList;