import React from 'react';
import { Routes, Route, Navigate } from 'react-router-dom';
import Dashboard from './pages/dashboard/Dashboard';
import ProductsList from './pages/dashboard/ProductsList';
import ProductDetail from './pages/dashboard/ProductDetail';
import Login from './pages/auth/Login';
import ResetPassword from './pages/auth/ResetPassword';
import PNF from './pages/404';
import { checkLogin, checkRole } from './auth';
import Signup from './pages/auth/Signup';
import Setting from './pages/dashboard/Setting';
import OrdersList from './pages/dashboard/OrdersList';
import ChangePassword from './pages/auth/ChangePassword';
import AddProduct from './pages/dashboard/AddProduct';
import VendorsList from './pages/dashboard/VendorsList';
import VendorDetail from './pages/dashboard/VendorDetail';
import AccountSuspended from './pages/dashboard/AccountSuspended';
import CreateVendor from './pages/dashboard/CreateVendor';
import UserProfile from './pages/dashboard/UserProfile';
import CustomerSupport from './pages/dashboard/CustomerSupport';
import OrderDetails from './pages/dashboard/OrderDetails';
import VendorOrdersList from './pages/dashboard/VendorOrdersList';
import VendorProductsList from './pages/dashboard/VendorProductsList';
import TwoStepVerification from './pages/auth/TwoStepVerification';
import ShopifyProductList from './pages/dashboard/ShopifyProductList';
import ProductDetailApproved from './pages/dashboard/ProductDetailApproved';


const Router = () => {
  return (
    <>
      <Routes>
        {/* auth-routes */}
        <Route
          exact
          path='/'
          element={!checkLogin() ? <Login /> : <Navigate to='/dashboard' replace={true} />}
        />
        <Route
          exact
          path='/signup'
          element={!checkLogin() ? <Signup /> : <Navigate to='/' />}
        />
        <Route
          exact
          path='/resetpassword'
          element={!checkLogin() ? <ResetPassword /> : <Navigate to='/' />}
        />
        <Route
          exact
          path='/changepassword'
          element={!checkLogin() ? <ChangePassword /> : <Navigate to='/' />}
        />
        <Route path="/userverification/:userToken"
          element={!checkLogin() ? <TwoStepVerification /> : <Navigate to='/' />}
        />

        {/* dashboard routes */}

        <Route
          exact
          path='/dashboard'
          element={checkLogin() ? <Dashboard /> : <Navigate to='/' />}
        />
        <Route
          exact
          path='/productslist'
          element={checkLogin() ? <ProductsList /> : <Navigate to='/' />}
        />
        <Route
          exact
          path='/shopifyproductslist'
          element={checkLogin() && checkRole() ? <ShopifyProductList /> : <Navigate to='/' />}
        />
        <Route
          exact
          path='/addproduct'
          element={checkLogin() ? <AddProduct /> : <Navigate to='/' />}
        />
        <Route
          exact
          path='/orderslist'
          element={checkLogin() ? <OrdersList /> : <Navigate to='/' />}
        />
        <Route
          exact
          path='/vendorslist'
          element={checkLogin() ? <VendorsList /> : <Navigate to='/' />}
        />
        <Route
          exact
          path='/vendordetail/:userId'
          element={checkLogin() && checkRole() ? <VendorDetail /> : <Navigate to='/' />}
        />
        <Route
          exact
          path='/createvendor'
          element={checkLogin() && checkRole() ? <CreateVendor /> : <Navigate to='/' />}
        />
        <Route
          exact
          path='/setting'
          element={checkLogin() && checkRole() ? <Setting /> : <Navigate to='/' />}
        />
        <Route path="/product/notapproved/:productId"
          element={checkLogin() ? <ProductDetail /> : <Navigate to='/' />}
        />
        <Route path="/product/approved/:productId"
          element={checkLogin() ? <ProductDetailApproved /> : <Navigate to='/' />}
        />
        <Route path="/order/:orderId"
          element={checkLogin() ? <OrderDetails /> : <Navigate to='/' />}
        />
        <Route path="/vendororder/:orderId"
          element={checkLogin() && checkRole() ? <VendorOrdersList /> : <Navigate to='/' />}
        />
        <Route path="/vendorproduct/:productId"
          element={checkLogin() && checkRole() ? <VendorProductsList /> : <Navigate to='/' />}
        />
        <Route path="/accountsuspended"
          element={!checkLogin() ? <AccountSuspended /> : <Navigate to='/' />}
        />
        <Route path="/userprofile"
          element={checkLogin() ? <UserProfile /> : <Navigate to='/' />}
        />
        <Route path="/customersupport"
          element={<CustomerSupport />}
        />
        <Route path='*' element={<PNF />} />
      </Routes>

    </>
  );
};

export default Router;
