import { useState } from 'react';

export function ProductImageUpload({ onUpload }) {
    const ACCEPTED_MIME_TYPE = ['image/jpg', 'image/jpeg', 'image/png', 'image/webp'];
    const [images, setImages] = useState([]);
    const [errorMessage, setErrorMessage] = useState('');

    const handleDrop = (event) => {
        event.preventDefault();
        event.stopPropagation();

        const acceptedFiles = Array.from(event.dataTransfer.files);
        // const rejectedFiles = acceptedFiles.filter((file) => !file.type.includes('image'));
        const rejectedFiles = acceptedFiles.filter((file) => !ACCEPTED_MIME_TYPE.includes(file.type));

        if (rejectedFiles.length > 0) {
            setErrorMessage('Only JPG, JPEG, PNG and WEBP image files are allowed.');
        } else {
            setErrorMessage('');
            const updatedImages = [...images, ...acceptedFiles];
            setImages(updatedImages);
            onUpload(updatedImages);
        }
    };

    const handleRemoveImage = (index) => {
        const updatedImages = images.filter((_, i) => i !== index);
        setImages(updatedImages);
        onUpload(updatedImages);
    };

    return (
        <div className="w-full">
            <label className="block mb-2">
                <span className="text-md font-medium text-slate-700 mb-3">Product Images</span>
            </label>
            <div className="mt-4 mb-6">
                <div
                    className="border-2 rounded-md cursor-pointer flex flex-col gap-4 items-center justify-center bg-gray-100"
                    onDrop={handleDrop}
                    onDragOver={(event) => {
                        event.preventDefault();
                        event.stopPropagation();
                    }}
                >
                    <input
                        type="file"
                        multiple
                        accept="image/jpeg, image/png, image/jpg, image/webp"
                        onChange={(event) => {
                            const acceptedFiles = Array.from(event.target.files);
                            const rejectedFiles = acceptedFiles.filter((file) => !ACCEPTED_MIME_TYPE.includes(file.type));
                            
                            if (rejectedFiles.length > 0) {
                                setErrorMessage('Only JPG, JPEG, PNG and WEBP image files are allowed.');
                            } else {
                                setErrorMessage('');
                                const updatedImages = [...images, ...acceptedFiles];
                                setImages(updatedImages);
                                onUpload(updatedImages);
                            }
                        }}
                        className="hidden"
                        id="fileUpload" // Add an id to the file input
                    />
                    <label htmlFor="fileUpload" className="relative w-full h-32 mt-4 mb-6 flex flex-col items-center gap-2 cursor-pointer place-content-center">
                        <svg
                            className="bg-purple-600 p-2 rounded-full cursor-pointer"
                            xmlns="http://www.w3.org/2000/svg"
                            xmlnsXlink="http://www.w3.org/1999/xlink"
                            enableBackground="new 0 0 50 50"
                            height="40px"
                            version="1.1"
                            viewBox="0 0 50 50"
                            width="40px"
                            xmlSpace="preserve"
                        >
                            <rect fill="none" height="40" width="40" />
                            <line fill="none" stroke="#fff" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" strokeWidth="4" x1="9" x2="41" y1="25" y2="25" />
                            <line fill="none" stroke="#fff" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" strokeWidth="4" x1="25" x2="25" y1="9" y2="41" />
                        </svg>
                        <div className="pointer-events-none">
                            <p className="text-gray-400 font-medium text-sm">Drag &amp; drop some files here, or click to select files</p>
                            <p className="text-purple-400 font-medium text-xs text-center">use file with these .jpg, .jpeg, .png and .webp only</p>
                        </div>
                    </label>
                </div>
            </div>

            {errorMessage && <div className="mb-4 bg-red-100 border border-red-200 text-red-700 px-2 py-2 rounded relative" role="alert">
                <span className="block sm:inline text-xs">{errorMessage}</span>
            </div>}
            <div className="flex flex-wrap gap-4">
                {images.map((image, index) => (
                    <div key={index} className="flex flex-col items-center">
                        <div className="relative">
                            <img
                                className="h-28 w-28 object-cover rounded-md"
                                src={URL.createObjectURL(image)}
                                alt={`Product Image ${index + 1}`}
                            />
                            <button
                                type="button"
                                className="absolute top-2 right-2 text-red-600"
                                onClick={() => handleRemoveImage(index)}
                            >
                                <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    className="h-5 w-5 fill-current"
                                    viewBox="0 0 20 20"
                                    fill="currentColor"
                                >
                                    <path
                                        fillRule="evenodd"
                                        d="M10 2a8 8 0 100 16 8 8 0 000-16zM7.707 8.293a1 1 0 011.414 0L10 9.586l1.879-1.88a1 1 0 111.414 1.414L11.414 11l1.879 1.879a1 1 0 01-1.414 1.414L10 12.414l-1.879 1.88a1 1 0 01-1.414-1.414L8.586 11 6.707 9.121a1 1 0 010-1.414z"
                                        clipRule="evenodd"
                                    />
                                </svg>
                            </button>
                        </div>
                        <span className="text-xs truncate md:text-clip">{image.name}</span>
                    </div>
                ))}
            </div>
        </div>
    );
}
