import React, { useEffect, useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { vendorData, fetchProductDetails, updateProductPrice, updateProductVendor, updateProductStatus, deleteProductsByVendor } from '../../utils/api';
import { getProductDetailFields } from '../../utils/productDetailFields';
import CGMDescription from '../../components/quill/CGMDescription';
import Loader from '../../contexts/Loader';
import StatusPill from '../../components/datalist/StatusPill';
import Table from '../../components/datalist/Table';
import PriceUpdateForm from '../../contexts/PriceUpdateForm';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import SelectOptions from '../../contexts/SelectOptions';
import { checkRole } from '../../auth';
import noImage from "../../assets/noImage.png";
import { ConfirmAlert } from '../../contexts/ConfirmAlert';

const ProductDetail = () => {
  const { productId } = useParams();
  const navigate = useNavigate();
  const [data, setData] = useState({});
  const [variantData, setVariantData] = useState([]);
  const [showLoader, setShowLoader] = useState(true);
  const [selectedVariant, setSelectedVariant] = useState({});
  const [error, setError] = useState('');
  const [vendors, setVendors] = useState([]);
  const [vendorError, setVendorError] = useState('');
  const [tableShowLoader, setTableShowLoader] = useState(true);
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [isDeleting, setIsDeleting] = useState(false);
  const [isPerformingAction, setIsPerformingAction] = useState(false);
  const [openDeleteAlert, setOpenDeleteAlert] = useState(false);
  const [isSubmittedVendor, setIsSubmittedVendor] = useState(null);


  const onCloseDeleteAlert = () => {
    setOpenDeleteAlert(!openDeleteAlert);
  }

  const handleProductDetails = async (queryString) => {
    try {
      setTableShowLoader(true);
      setShowLoader(true);
      const response = await fetchProductDetails(queryString, setData);
      if(response.data.product.approved){
        window.location.href = "/product/approved/"+productId;
      }

      if (response.data && response.data.product) {
        const preparedData = await dataPrepare(response.data.product);
        setData(preparedData);
        const variantData = await variantDataPrepare(response.data.product.variants);
        setVariantData(variantData);
      } else {
        setError('Failed to fetch product details');
      }
    } catch (error) {
      setError('Failed to fetch product details');
    }
    finally {
      setTableShowLoader(false);
      setShowLoader(false);
    }
  };


  const handleFetchVendors = async (queryString) => {
    try {
      const response = await vendorData(queryString);
      if (response.data && response.data) {
        setVendors(response.data);
        setVendorError('');
      } else {
        setVendorError('Failed to fetch Vendors');
        setShowLoader(false);
      }
    } catch (error) {
      setVendorError('Failed to fetch Vendors', error);
    }
  }


  const variantDataPrepare = async (data) => {
    if (Array.isArray(data)) {
      return data;
    } else {
      return [];
    }
  };

  const dataPrepare = async (data) => {
    const imagePrepare = data.images.length > 0 ? data.images.map((image, i) => (
      <img
        key={image.id}
        src={image.src}
        alt="Product Image"
        className={`product-images w-full ${data.images.length > 2 ? 'md:w-1/2 lg:w-1/2 p-1' : 'p-1 pb-4 md:w-full lg:w-full'} p-1 w-full h-full object-cover`}
      />
    )) : null;

    // const optionPrepare = data.options.map((option, i) => {
    //   // const optionValues = option.values.map((value) => (
    //   //   <option key={value} value={value}>
    //   //     {value}
    //   //   </option>
    //   // ));
    //   return (
    //     <div key={option.id} className='mb-4'>
    //       <label htmlFor={option.name} className='capitalize'>{option.name}</label>
    //       <select
    //         id={option.name}
    //         value={selectedVariant[option.name]}
    //         className="block w-full py-2 px-3 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:border-indigo-500"
    //       >
    //         {/* {optionValues} */}
    //       </select>
    //     </div>
    //   );
    // });
    return {
      vendor: data.vendor || 'No Vendor',
      title: data.title || 'No Title',
      description: data.body_html || 'No Description',
      approval: data.approved,
      images: imagePrepare || null,
      options: [data.options] || null,
      tags: data.tags !== undefined && data.tags != "" && [data.tags] || null,
      product_type: data.product_type || 'No Type',
      status: data.status || null,
      user_id: data.user_id
    };
  };
  
  const updatePrice = async (data) => {
    setVariantData();
    const pricePayload = {
      mid: productId,
      price_details: data,
    };
    try {
      setTableShowLoader(true);
      const response = await updateProductPrice(pricePayload);
      if (response.data.success === true) {
        const variantData = await variantDataPrepare(response.data.product.variants);
        setVariantData(variantData);
        toast.success("Price Updated", {
          position: toast.POSITION.TOP_CENTER
        });
      } else {
        return toast.error(response.error, {
          position: toast.POSITION.TOP_CENTER
        });
      }
    } catch (error) {
      toast.error("Not Updated", {
        position: toast.POSITION.TOP_CENTER
      });
    }
    finally {
      setTableShowLoader(false);
    }
  };


  useEffect(() => {
    handleProductDetails(productId);
    handleFetchVendors();
  }, [productId]);

  const selectVendor = async (vendorId) => {
    setIsSubmittedVendor(false);
    const pricePayload = {
      vendor_id: vendorId,
      products: [productId]
    }
    toast.dismiss();
    try {
      const response = await updateProductVendor(pricePayload);
      if (response.data.success === true) {
        setIsSubmittedVendor(null);
        return toast.success("Vendor Updated", {
          position: toast.POSITION.TOP_CENTER
        });
      }
      else {
        setIsSubmittedVendor(null);
        return toast.error(response.error, {
          position: toast.POSITION.TOP_CENTER
        });
      }
    } catch (error) {
      setIsSubmittedVendor(null);
      toast.error("Not Updated", {
        position: toast.POSITION.TOP_CENTER
      });
    }
  }
  
  const handleBackPage = (e) => {
    e.stopPropagation();
    // navigate(-1);
    window.location.href = '/productslist';
    // navigate('/productslist', {replace: true});
    // window.location.reload();
  }

  const productApprovalHandler = async () => {
    let products = [];
    products.push({ mid: productId });
    const postData = { action: "createProduct", data: products, processedData: []};
    try {
      // Simulating API call response.data.success
      const response = await updateProductStatus(postData);
      if (response.data.success === true) {
        setIsSubmitted(true);
        toast.success('Product is approved. Please wait...', {
          position: toast.POSITION.TOP_CENTER,
        });
        setTimeout(()=>{
          window.location.href = "/product/approved/"+productId;
        }, 3200);
      }
      else if(response.data.success === false && response.data.message === "Bulk Operation is already on going"){
        toast.error('Bulk Operation is already on going. Try after sometime.', {
          position: toast.POSITION.TOP_CENTER,
        });
      }
    } catch (error) {
      toast.error('Something went wrong', {
        position: toast.POSITION.TOP_CENTER,
      });
    }
  }

  const productDeleteHandler = async () =>{
    let products = [];
    products.push({ mid: productId });
    try {
      // Simulating API call response.data.success
      const response = await deleteProductsByVendor(products);
      if (response.data.success === true) {
        setIsPerformingAction(true);
        toast.success('Product is deleting. Please wait...', {
          position: toast.POSITION.TOP_CENTER,
        });
      }
      else if(response.data.success === false){
        toast.error('Something went wrong', {
          position: toast.POSITION.TOP_CENTER,
        });
      }
      setTimeout(()=>{
        window.location.href = "/productslist";
      }, 3200);
    } catch (error) {
      toast.error('Internal server error. Try again after sometime.', {
        position: toast.POSITION.TOP_CENTER,
      });
    }
  }
  
  return (
    <>
      {showLoader ? (
        <Loader setShowLoader={setShowLoader} />
      ) : (
        <div>
          {error &&
            <div className="bg-red-100 border border-red-200 text-red-700 px-2 py-2 rounded relative" role="alert">
              <span className="block sm:inline text-xs">{error}</span>
            </div>
          }
          {data && Object.keys(data).length > 0 && (
            <div>
              <div className="flex flex-col gap-5 items-start justify-between pb-6 border-b lg:items-center lg:space-y-0 lg:flex-row md:flex-row">
                <div className='flex gap-2'>
                  <button onClick={handleBackPage} class="p-1 border border-transparent text-sm font-medium rounded-md text-white bg-purple-400 hover:bg-purple-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-purple-500"><svg class="h-5 w-5" fill="none" viewBox="0 0 24 24" stroke="currentColor"><path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M15 19l-7-7 7-7"></path></svg></button>
                  <h1 className="text-2xl font-semibold whitespace-nowrap">Products Details</h1>
                </div>
                <div className='flex gap-2'>
                  {checkRole() && <button disabled={isSubmitted} className="flex gap-2 text-white right-2.5 bottom-1.5 py-2 px-5 border border-transparent text-sm font-medium rounded-md text-white bg-purple-600 hover:bg-purple-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-purple-500" onClick={() => {productApprovalHandler()}}>
                    {isSubmitted === true ? <><svg className="animate-spin h-4 w-4 mr-1 text-white" viewBox="0 0 24 24">
                      <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4" />
                      <path
                          className="opacity-75"
                          fill="currentColor"
                          d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-1.647zm4-1.647l3 1.647C11.865 17.824 13 15.042 13 12H8a7.962 7.962 0 01-.729 3.291z"
                      />
                    </svg><span>approving...</span></> : "Not approved"}
                  </button>}
                  <button disabled={isDeleting} className="flex gap-2 text-white right-2.5 bottom-1.5 py-2 px-5 border border-transparent text-sm font-medium rounded-md text-white bg-red-500 hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-purple-500" 
                    onClick={()=>{setOpenDeleteAlert(true)}}>
                    {isDeleting === true ? <><svg className="animate-spin h-4 w-4 mr-1 text-white" viewBox="0 0 24 24">
                      <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4" />
                      <path
                          className="opacity-75"
                          fill="currentColor"
                          d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-1.647zm4-1.647l3 1.647C11.865 17.824 13 15.042 13 12H8a7.962 7.962 0 01-.729 3.291z"
                      />
                    </svg><span>deleting...</span></> : "Delete"}
                  </button>
                  <ConfirmAlert openDeleteAlert={openDeleteAlert} onCloseDeleteAlert={onCloseDeleteAlert} onAction={productDeleteHandler} isPerformingAction={isPerformingAction} orderMessage={false}/>
                </div>
                {/* <p>Product Id: {productId}</p> */}
              </div>
              <div className="flex flex-wrap">
                <div className="w-full md:w-1/2 lg:w-1/3 p-4">
                  {data.images && data.images != undefined ? (
                    <div className="flex flex-wrap">{data.images}</div>
                  ) : (
                    <div className="flex flex-col items-center bg-slate-300 h-68 p-4 justify-center">
                       <img
                          src={noImage}
                          alt="Product Image"
                          className="product-images w-full p-1 w-full h-full object-cover"
                        />
                    </div>
                  )}
                </div>
                <div className="w-full md:w-1/2 lg:w-2/3">
                  <div className='bg-gray-50 p-4 mt-5 relative'>
                    <div className={`text-1xl mb-2 absolute px-4 py-2 font-bold top-0 left-0 mb-4 w-full text-center ${data.approval ? "shadow-sm bg-blue-100 text-blue-700" : "shadow-sm bg-pink-100 text-pink-700"}`}>
                      {data.approval ? "Product Approved By Admin" : "Product Not Approved By Admin"}
                    </div>
                    <h1 className="text-2xl font-bold mb-4 mt-10">{data.title ? (data.title) : "No Title"}</h1>
                    <h2 className="text-1xl mb-2 "><span className="font-bold">Vendor: </span>{data.vendor ? (data.vendor) : "No Vendor"}</h2>
                    <div className='text-1xl mb-4'><span className="font-bold">Product type: </span><span className='capitalize'>{data.product_type ? (data.product_type) : "No Type"}</span></div>
                    <div className='text-1xl mb-4'><span className="font-bold">Status: </span>{data.status ? <StatusPill value={data.status} /> : " No Status"} </div>
                    <div className='mb-4'><span className="font-bold">Description: </span><CGMDescription toolbar={false} value={`${data.description ? data.description : "No Description"}`} /></div>
                    <div className="text-1xl mb-4 pr-3"><span className="font-bold">Tags: </span>
                      {data.tags && data.tags?.length > 0 ? 
                        data.tags.map((value) => {
                          const updateTags = value.split(",");
                          return updateTags.map((tag) => (
                            <span key={tag} className="ml-2 inline-block px-3 py-1 text-xs font-semibold text-white bg-gray-400 rounded-full">{tag}</span>
                          ));
                        }) : " No tags"}
                    </div>
                    {/* {data.options && (
                      <div>{data.options}</div>
                    )} */}
                    <div className="text-1xl mb-4 pr-3">
                      <span className="font-bold">Options: </span>
                      {data.options && data.options.length > 0 ? (
                        data.options[0].map((value) => {
                          return (
                            <span key={value} className="ml-2 mr-2 inline-block px-3 py-1 text-xs font-semibold text-white bg-gray-400 rounded-full">{value}</span>
                          )
                        })
                      ) : "No tags"}
                    </div>
                    {/* flex gap-4 items-start w-full sm:w-1/2*/}
                    {checkRole() && <div className="grid grid-cols-1 gap-5 mt-6 sm:grid-cols-1 xl:grid-cols-2 2xl:grid-cols-2">
                      <div className="">
                        <PriceUpdateForm updatePrice={updatePrice} />
                      </div>
                      <div className="">
                        <SelectOptions vendorsData={vendors} vendorError={vendorError} selectVendor={selectVendor} isSubmittedVendor={isSubmittedVendor} vendorId={data.user_id}/>
                      </div>
                    </div>}

                  </div>
                </div>
              </div>
              <div className='sticky--remove'>
                <Table
                  columns={getProductDetailFields()}
                  data={Array.isArray(variantData) && variantData.length > 0 ? variantData : []}
                  dimension={{ width: '', maxHeight: '300px' }}
                  showLoader={tableShowLoader}
                />
              </div>
            </div>
          )}
        </div>
      )}
      <ToastContainer />
    </>
  );
};

export default ProductDetail;
