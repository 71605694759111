import React, { useEffect, useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { vendorData, updateVendorData } from '../../utils/api';
import Loader from '../../contexts/Loader';
import Breadcrumb from '../../contexts/Breadcrumb';
import { vendorDetailFiled } from "../../utils/vendorDetailField";
import Input from '../../contexts/Input';
import FormAction from '../../contexts/FormAction';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const VendorDetail = () => {

    const { userId } = useParams();
    const [showLoader, setShowLoader] = useState(true);
    const requiredFields = ['username', 'email', 'role'];
    const [successMessage, setSuccessMessage] = useState(false);
    const [error, setError] = useState(null);
    const [data, setData] = useState({
        username: null,
        email: null,
        role: null,
        status: false
    });
    const [isSubmitted, setIsSubmitted] = useState(null);

    const navigate = useNavigate();

    useEffect(() => {
        handleFetchData(userId);
    }, [userId]);

    const fields = vendorDetailFiled;
    const [userField, setUserField] = useState(Object.fromEntries(fields.map((field) => [field.name, ""])));
    const handleChange = (e) => {
        setUserField({ ...userField, [e.target.name]: e.target.value });
        const { name, value } = e.target;
        const newValue = name === "status" ? value === "true" : value;
        setData((prevFields) => ({ ...prevFields, [name]: newValue }));
    }

    const handleSubmit = (e) => {
        e.preventDefault();
        setIsSubmitted(false);
        const action = e.nativeEvent.submitter.innerHTML.toLowerCase();
        if (action.includes('back')) {
            navigate(-1, { replace: true });
        } else {
            for (const field of requiredFields) {
                if (!data[field]) {
                    console.log(data[field], userField[field], data[field])
                    return setError(`${field.replace('_', ' ')} field is required.`);
                }
            }
            updateData(data, userId);
        }
    }


    const updateData = async (data, queryString) => {
        const response = await updateVendorData(data, queryString);
        toast.dismiss();
        if (response.status == 201) {
            toast.success("Status Updated", {
                position: toast.POSITION.TOP_CENTER,
                autoClose: 2500,
            });
        }
        else {
            toast.error(response.error, {
                position: toast.POSITION.TOP_CENTER,
                autoClose: 2500
            });
        }
        setIsSubmitted(null);
    }

    const handleFetchData = async (queryString) => {
        try {
            const response = await vendorData(queryString, setData);
            setData({
                username: response.data?.[0]?.username,
                email: response.data?.[0]?.email,
                role: response.data?.[0]?.role,
                status: response.data?.[0]?.status,
            });
            setError(response.error);
        } catch (error) {
            setError(error);
        }
    };

    return (

        <>
            {
                showLoader ?
                    (<Loader setShowLoader={setShowLoader} />) :
                    (<div>
                        <div className="border-b pb-6">
                            <div className="flex flex-col items-start justify-between pb-2 space-y-4 lg:items-center lg:space-y-0 lg:flex-row">
                                <h1 className="text-2xl font-semibold whitespace-nowrap">Vendor Deatils</h1>
                                <Breadcrumb />
                            </div>
                        </div>
                        <div className="grid grid-cols-12 gap-5">
                            <div className="col-span-12 lg:col-span-6 mt-8 space-y-6">
                                <div className="max-w-md">
                                    <h2 className="text-lg font-medium mb-4">Change the status of vendor</h2>
                                    <ol className="list-decimal pl-6 mb-6 ">
                                        <li className="mb-2 text-gray-800">If the admin changes the status of a vendor, it can result in the vendor being blocked.<a href="#" className="text-blue-500 hover:underline">https://your-store-name.myshopify.com/admin</a>.</li>
                                        <li className="mb-2 text-gray-800">When the status of a vendor is changed to a blocked state, the vendor will not be able to log in to their account.</li>
                                        <li className="mb-2 text-gray-800">This action is typically performed by the admin to restrict access or suspend the vendor's account temporarily.</li>
                                        <li className="mb-2 text-gray-800">The blocked status prevents the vendor from accessing their dashboard, managing their products, and performing any vendor-related activities.</li>
                                        <li className="mb-2 text-gray-800">The vendor will need to contact the admin or follow the necessary steps to have their account unblocked or reinstated.</li>
                                        <li className="mb-2 text-gray-800">It's important for the admin to communicate the reason for blocking the vendor's account and any steps that need to be taken for resolution.</li>
                                        <li className="mb-2 text-gray-800">The status change should be clearly indicated to the vendor, either through a notification or an email, explaining the blocked status and providing any relevant instructions or contact information for further assistance.</li>
                                    </ol>
                                </div>
                            </div>
                            <div className="col-span-12 lg:col-span-6">
                                <form className="bg-gray-50 mt-8 space-y-6 rounded-md shadow-md border p-4 py-6 sticky top-0" onSubmit={handleSubmit}>
                                    <div className="-space-y-px">

                                        {fields.map((field) => (
                                            <div key={field.name}>
                                                <label
                                                    htmlFor={field.name}
                                                    className="block text-sm font-medium text-gray-700"
                                                >
                                                    {field.labelText}
                                                </label>
                                                <div className="mt-1">
                                                    <Input
                                                        key={field.name}
                                                        handleChange={handleChange}
                                                        value={data[field.name]}
                                                        labelText={field.labelText || field.placeholder}
                                                        labelFor={field.labelFor || field.placeholder}
                                                        id={field.id}
                                                        name={field.name}
                                                        type={field.type}
                                                        placeholder={field.placeholder}
                                                        options={field.options}
                                                        pattern={field.pattern}
                                                        inputMode={field.inputMode}
                                                        isReadonly={field.isReadonly}
                                                        customclassName=""
                                                    />
                                                </div>
                                            </div>
                                        ))}
                                    </div>
                                    {error &&
                                        <div className="bg-red-100 border border-red-200 text-red-700 px-2 py-2 rounded relative" role="alert">
                                            <span className="block sm:inline text-xs">{error}</span>
                                        </div>
                                    }
                                    <div className="flex gap-4 w-32 mt-5">
                                        <FormAction text="Save" data-action="save" isSubmitted={isSubmitted} disabled={isSubmitted === false ? true : false} />
                                        <FormAction text="Back" classes="bg-red-500 hover:bg-red-700" data-action="back" />
                                    </div>
                                </form>
                            </div>
                        </div >
                    </div >
                    )
            }
            <ToastContainer/>

        </>

    );

}

export default VendorDetail;