import React, { useState, useEffect, useCallback, memo } from 'react';
import { Link } from 'react-router-dom';
import Modal from '../../contexts/Modal';
import CSVUpload from '../../contexts/CSVUpload';
import { vendorDataFields } from "../../utils/vendorDataFields";
import { vendorData, fetchOrderData } from '../../utils/api';
import useAuth from '../../hooks/auth-hook';
import { getUserInfo, importProductCSV, getSampleProductCSV } from '../../utils/api';
import useVendorStatus from '../../hooks/vendor-hook';
import PieChart from '../../contexts/PieChart';
import LineChart from '../../contexts/LineChart';
import Loader from '../../contexts/Loader';
import Avatar from '../../assets/avatar.png';
import Calendar from 'react-calendar';
import RandomAvatar from '../../contexts/RandomAvatar';
import 'react-calendar/dist/Calendar.css';
import { checkRole } from '../../auth';
import CSVExportSample from '../../contexts/CSVExportSample';

const Dashboard = () => {
  const { role, name } = useAuth();
  const [showLoader, setShowLoader] = useState(true);
  const [error, setError] = useState(null);
  const [loader, setLoader] = useState(false)
  const [csvStatusUpdate, setCsvStatusUpdate] = useState(false)
  const [csvError, setCsvError] = useState(false);
  const vendorInfo = useVendorStatus();
  const [modalOpen, setModalOpen] = useState(false);
  const [modalOpenSampleCSV, setModalOpenSampleCSV] = useState(false);

  const [data, setData] = useState([]);
  const [userInfo, setUserInfo] = useState(null);
  const [limitedData, setLimitedData] = useState([]);
  const [value, onChange] = useState(new Date());
  const salesData = vendorInfo && vendorInfo.ordersByMonth ? vendorInfo.ordersByMonth.sales : [0, 0, 0, 0, 0, 0];
  const ordersData = vendorInfo && vendorInfo.ordersByMonth ? vendorInfo.ordersByMonth.orders : [0, 0, 0, 0, 0, 0];

  const handleOpenModal = () => {
    setModalOpen(true);
  };

  const handleCloseModal = () => {
    setModalOpen(false);
    setCsvStatusUpdate(false);
    setLoader(false);
    setCsvError(false);
  };

  const handleOpenModalSampleCSV = () => {
    setModalOpenSampleCSV(true);
  };

  const handleCloseModalSampleCSV = () => {
    setModalOpen(false);
    setModalOpenSampleCSV(false);
    setLoader(false);
    setCsvError(false);
  };

  const handleUpload = async (formData) => {
    setLoader(true);

    try {
      const response = await importProductCSV(formData);
      await new Promise((resolve) => setTimeout(resolve, 2000)); // Wait for 2 seconds
      if (response.data.success && response.data.success == true) {
        setLoader(false);
        setCsvStatusUpdate(true);
        setCsvError(false);
      }
    } catch (error) {
      setLoader(false);
      setCsvStatusUpdate(false);
      setCsvError(true);
    }
  };

  //load the data automatically
  useEffect(() => {
    if (vendorInfo) {
      setUserInfo(vendorInfo.info);
      setShowLoader(false);
      if (role === "admin") {
        handleFetchData();
      }
      else {
        handleFetchData();
      }
    }
  }, [vendorInfo]);
  
  const handleFetchData = useCallback(async (queryString) => {
    try {
      if (role === "admin") {
        const response = await vendorData(queryString);
        setData(response.data);
        await sliceData(response.data);
        setError(response.error);
      }
      else if (role === "vendor") {
        const response = await fetchOrderData('page=1&perPage=50');
        setData(response.data);
        await sliceData(response.data.orders);
      }
    } catch (error) {
      setError(error);
    } finally {
      setShowLoader(false);
    }
  }, [role]);

  const sliceData = async (data) => {
    if (data && data.length > 0) {
      return setLimitedData(data.slice(0, 7));;
    }
    return [];
  };

  const csvExportSample = async () => {
    try {
      const response = await getSampleProductCSV();
      if (response.data.status === 201) {
        return true;
      }
      else {
        return false;
      }
    } catch (error) {
      return false;
    }
  }




  return (
    <>
      {
        showLoader ?
          (<Loader setShowLoader={setShowLoader} time='10000' />) :
          (
            <>
              {/* main-content-header */}
              <div className="ltablet:max-w-full flex justify-between flex-col items-center gap-4 text-center md:flex-row md:text-left lg:max-w-full border-b pb-6">
                <div className="flex flex-col items-start justify-between pb-2 space-y-4  lg:items-center lg:space-y-0 lg:flex-row gap-2">
                  <div className="relative inline-flex shrink-0 items-center justify-center outline-none h-16 w-16 rounded-full">
                    <div className="flex h-full w-full items-center justify-center overflow-hidden text-center transition-all duration-300 rounded-full">
                      <img src={Avatar} className="max-h-full max-w-full object-cover shadow-sm dark:border-transparent h-16 w-16" />
                    </div>
                  </div>
                  <div>
                    <h2 className="font-heading text-xl font-light leading-tight text-muted-800 dark:text-white">
                      <span className='font-semibold'>Welcome back, {name}!</span>
                    </h2>
                    <p className="font-alt text-base font-normal leading-normal leading-normal">
                      <span className="font-medium text-gray-400"> Happy to see you again on your dashboard. </span>
                    </p>
                  </div>
                </div>
                {!checkRole() && <div className="flex flex-row">
                  <div class="mr-2">
                    <button onClick={handleOpenModal} className="inline-flex items-center justify-center px-4 py-1 space-x-1 bg-pink-500 text-white rounded-md shadow hover:bg-pink-600">Import CSV</button>
                  </div>
                  <div class="mr-2">
                    <button onClick={handleOpenModalSampleCSV} className="inline-flex items-center justify-center px-4 py-1 space-x-1 bg-purple-500 text-white rounded-md shadow hover:bg-purple-600">Sample CSV</button>
                  </div>
                </div>}

              </div>

              <Modal isOpen={modalOpenSampleCSV} onClose={handleCloseModalSampleCSV} heading={"CSV UPLOAD"}>
                <h2 className="text-lg font-medium mb-4">How to import the product using CSV</h2>
                <ol className="list-decimal pl-6 mb-6 ">
                  <li className="mb-2 text-gray-800">Firstly, download the sample CSV file.</li>
                  <>
                    <li className="mb-2 text-gray-800">Open the CSV file and enter the product details of each column.</li>
                    <p className="mb-2 text-purple-800">Note: Don't make any changes in the CSV header otherwise the file can't be uploaded.</p>
                  </>
                  <li className="mb-2 text-gray-800">Cross check and verify all the details of the file.</li>
                  <li className="mb-2 text-gray-800">Lastly, use the updated csv file and import it.</li>
                  <li className="mb-2 text-gray-800">To know which columns must contains values, you can check this Shopify <a target="_balnk" href="https://help.shopify.com/en/manual/products/import-export/using-csv" className="text-blue-500 hover:underline">documentation</a>.</li>
                </ol>
                <p className="mb-2 text-black-800">FYI: While importing the CSV data if the product title already exist in our database then we will overwrite it and update it with new data.</p>
                <div class="mt-5 center-align">
                  <CSVExportSample csvExportSample={csvExportSample} />
                </div>

              </Modal>

              <Modal isOpen={modalOpen} onClose={handleCloseModal} heading={"CSV UPLOAD"}>
                <CSVUpload onUpload={handleUpload} csvStatusUpdate={csvStatusUpdate} loader={loader} csvError={csvError} />
              </Modal>
              {/* start-content */}

              <div className={`grid grid-cols-1 gap-5 mt-6 sm:grid-cols-2 ${role === "admin" ? 'xl:grid-cols-4 2xl:grid-cols-4' : 'lg:grid-cols-3'}`}>
                {userInfo ? (
                  userInfo.map((element) => {
                    if (element.name === "Orders") {
                      return (<div className="bg-gray-50 p-4 transition-shadow border rounded-lg shadow-sm hover:shadow-lg" key={`key-${element.name}`}>
                        <div className="flex items-start justify-between">
                          <div className="flex flex-col space-y-2">
                            <span className="text-gray-400">Total {element.name}</span>
                            <span className="text-lg font-semibold">{element.count.toLocaleString("au-AU")}</span>
                          </div>
                          <div className="p-3 rounded bg-blue-300">
                            <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6 text-white-600" fill="none" viewBox="0 0 24 24" stroke="#fff">
                              <circle cx="9" cy="21" r="1" className="text-white-400" />
                              <circle cx="20" cy="21" r="1" className="text-white-400" />
                              <path
                                d="M1 1h4l2.68 13.39a2 2 0 0 0 2 1.61h9.72a2 2 0 0 0 2-1.61L23 6H6"
                                className="text-white-600 "
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                strokeWidth="2"
                                stroke="#fff"
                              />
                            </svg>
                          </div>
                        </div>
                        <PieChart data={element} type={element.name} />
                      </div>)
                    }
                    else if (element.name === "Vendors") {
                      return (<div className="bg-gray-50 p-4 transition-shadow border rounded-lg shadow-sm hover:shadow-lg" key={`key-${element.name}`}>
                        <div className="flex items-start justify-between">
                          <div className="flex flex-col space-y-2">
                            <span className="text-gray-400">Total {element.name}</span>
                            <span className="text-lg font-semibold">{element.count.toLocaleString("au-AU")}</span>
                          </div>
                          <div className="p-3 rounded bg-purple-300">
                            <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6 text-white-600" fill="none" viewBox="0 0 24 24" stroke="#fff">
                              <path
                                d="M12 14C14.7614 14 17 11.7614 17 9C17 6.23858 14.7614 4 12 4C9.23858 4 7 6.23858 7 9C7 11.7614 9.23858 14 12 14Z"
                                className="text-white-400"
                              />
                              <path
                                d="M18 21V19C18 17.9391 17.5786 16.9217 16.8284 16.1716C16.0783 15.4214 15.0609 15 14 15H10C8.93913 15 7.92172 15.4214 7.17157 16.1716C6.42143 16.9217 6 17.9391 6 19V21M12 11C13.6569 11 15 9.65685 15 8C15 6.34315 13.6569 5 12 5C10.3431 5 9 6.34315 9 8C9 9.65685 10.3431 11 12 11Z"
                                className="text-white-600"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                strokeWidth="2"
                                stroke="#fff"
                              />
                            </svg>
                          </div>
                        </div>
                        <PieChart data={element} type={element.name} />
                      </div>)

                    }
                    else if (element.name === "Products") {
                      return (
                        <div className="bg-gray-50 p-4 transition-shadow border rounded-lg shadow-sm hover:shadow-lg" key={`key-${element.name}`}>
                          <div className="flex items-start justify-between">
                            <div className="flex flex-col space-y-2">
                              <span className="text-gray-400">Total {element.name}</span>
                              <span className="text-lg font-semibold">{element.count.toLocaleString("au-AU")}</span>
                            </div>
                            <div className="p-3 bg-gray-200 rounded bg-pink-300">
                              <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6 text-white-600" fill="none" viewBox="0 0 24 24" stroke="#fff" strokeLinecap="round" strokeLinejoin="round" >
                                <path
                                  d="M21 16V8a2 2 0 0 0-1-1.73l-7-4a2 2 0 0 0-2 0l-7 4A2 2 0 0 0 3 8v8a2 2 0 0 0 1 1.73l7 4a2 2 0 0 0 2 0l7-4A2 2 0 0 0 21 16z" className="text-white-600 "
                                  strokeLinecap="round"
                                  strokeLinejoin="round"
                                  strokeWidth="2"
                                  stroke="#fff"></path>
                                <polyline points="3.27 6.96 12 12.01 20.73 6.96"></polyline>
                                <line x1="12" y1="22.08" x2="12" y2="12"></line>
                              </svg>
                            </div>
                          </div>
                          <PieChart data={element} type={element.name} />
                        </div>
                      );
                    }
                    return null;
                  })
                ) : (
                  <div className="bg-gray-50 p-4 transition-shadow border rounded-lg shadow-sm hover:shadow-lg">
                    <p className="text-red-500 mt-2">Error: Failed to fetch data</p>
                  </div>
                )
                }
                <div className="bg-gray-50 p-4 transition-shadow border rounded-lg shadow-sm hover:shadow-lg">
                  <Calendar onChange={onChange} value={value} />
                </div>

              </div>
              <div className="mt-4 grid grid-cols-12 gap-6">
                <div className="bg-gray-50 transition-shadow border rounded-lg shadow-sm hover:shadow-lg ltablet:col-span-8 col-span-12 lg:col-span-8">
                  <div className="flex items-start justify-between p-4 border">
                    <div className="flex flex-col space-y-2">
                      <span className="text-gray-600 font-medium">Order Vs Sale</span>
                    </div>
                  </div>
                  <div className="p-3 bg-gray-200 rounded bg-blue-50">
                    <LineChart salesData={salesData} ordersData={ordersData} />
                  </div>
                </div>
                {role === "admin" ? (
                  <div className="bg-gray-50 transition-shadow border rounded-lg shadow-sm hover:shadow-lg ltablet:col-span-4 col-span-12 lg:col-span-4 overflow-y-auto" style={{ maxHeight: '485px' }}>
                    <div className="flex p-4 border sticky top-0 bg-gray-50 z-10">
                      <div className="flex justify-between w-full items-center">
                        <h3 className="font-heading text-base font-semibold leading-tight text-muted-800 dark:text-white">
                          <span className="text-gray-600 font-medium">Recently Added Vendor</span>
                        </h3>
                        <Link to="/vendorslist" className='bg-purple-600 hover:bg-purple-400 dark:bg-purple-700 dark:hover:bg-purple-900 text-white rounded-lg px-4 py-1 font-sans text-sm font-medium underline-offset-4 transition-colors duration-300'>View All </Link>
                      </div>
                    </div>
                    {data && limitedData.length > 0 ? (
                      limitedData.map((vendor, i) => (
                        <div className="p-3 bg-gray-200 rounded bg-blue-50 mb-2" key={i}>
                          <div className='mb-2 space-y-5'>
                            <div className="flex items-center gap-3">
                              <div className="relative inline-flex shrink-0 items-center justify-center outline-none h-10 w-10 rounded-full bg-primary-100 dark:bg-primary-500/20 text-primary-500 shrink-0">
                                <div className="flex h-full w-full items-center justify-center overflow-hidden text-center transition-all duration-300 rounded-full">
                                  <RandomAvatar className="max-h-full max-w-full object-cover shadow-sm dark:border-transparent h-10 w-10" customKey={i} />
                                </div>
                              </div>
                              <div>
                                <h4 className="font-heading text-sm font-light leading-tight text-muted-800 dark:text-white">
                                  <span>{vendor.username}</span>
                                </h4>
                                <p className="mb-0 text-gray-500 dark:text-white/70 text-xs flex font-normal"> {vendor.products} Products
                                  <svg className="fill-primary w-4 h-4 ltr:ml-2 rtl:mr-2" xmlns="http://www.w3.org/2000/svg" width="100" height="100" enableBackground="new 0 0 100 100" viewBox="0 0 100 100">
                                    <path className="fill-blue-500" d="M88.057,45.286l-5.456-5.455c-1.295-1.295-2.356-3.854-2.356-5.689v-7.715c0-3.67-2.998-6.668-6.667-6.67h-7.718  c-1.833,0-4.395-1.063-5.69-2.357l-5.455-5.455c-2.592-2.592-6.836-2.592-9.428,0l-5.455,5.459c-1.296,1.295-3.861,2.355-5.69,2.355  h-7.715c-3.665,0-6.667,2.998-6.667,6.668v7.715c0,1.828-1.061,4.395-2.356,5.689l-5.456,5.455c-2.594,2.592-2.594,6.836,0,9.432  l5.456,5.455c1.296,1.295,2.356,3.861,2.356,5.689v7.715c0,3.666,3.002,6.668,6.667,6.668h7.715c1.833,0,4.395,1.061,5.69,2.355  l5.455,5.457c2.592,2.59,6.836,2.59,9.428,0l5.455-5.457c1.296-1.295,3.857-2.355,5.69-2.355h7.718c3.669,0,6.667-3.002,6.667-6.668  v-7.715c0-1.836,1.062-4.395,2.356-5.689l5.456-5.455C90.647,52.122,90.647,47.878,88.057,45.286z M44.709,65.001L30,50.29  l4.714-4.713l9.996,9.996l20.577-20.572L70,39.714L44.709,65.001z"></path>
                                  </svg>
                                </p>
                              </div>
                              <div className="ms-auto flex items-center">
                                <span
                                  className={`text-sm px-2 py-1 text-sm font-semibold rounded ${vendor.status === false
                                    ? 'bg-yellow-500 text-white'
                                    : 'bg-green-500 text-white'
                                    }`}
                                >
                                  {vendor.status === false ? "Deactivate" : "Active"}
                                </span>
                              </div>
                            </div>
                          </div>
                        </div >
                      ))
                    ) : (
                      <>
                        {error && (
                          <div className="bg-red-100 border border-red-200 text-red-700 px-2 py-2 rounded relative" role="alert">
                            <span className="block sm:inline text-xs">{error}</span>
                          </div>
                        )}</>
                    )}
                  </div >) : (
                  <div className="bg-gray-50 transition-shadow border rounded-lg shadow-sm hover:shadow-lg ltablet:col-span-4 col-span-12 lg:col-span-4 overflow-y-auto" style={{ maxHeight: '485px' }}>
                    <div className="flex p-4 border">
                      <div className="flex justify-between w-full items-center">
                        <h3 className="font-heading text-base font-semibold leading-tight text-muted-800 dark:text-white">
                          <span className="text-gray-600 font-medium">Recent Orders</span>
                        </h3>
                        <Link to="/orderslist" className='bg-purple-600 hover:bg-purple-400 dark:bg-purple-700 dark:hover:bg-purple-900 text-white rounded-lg px-4 py-1 font-sans text-sm font-medium underline-offset-4 transition-colors duration-300'>View All </Link>
                      </div>
                    </div>
                    {data && limitedData.length > 0 ? (
                      limitedData.map((order, i) => (
                        <div className="p-3 bg-gray-200 rounded bg-blue-50 mb-2" key={i}>
                          <div className='mb-2 space-y-5'>
                            <div className="flex items-center gap-3">
                              <div className="relative inline-flex shrink-0 items-center justify-center outline-none h-10 w-10 rounded-full bg-primary-100 dark:bg-primary-500/20 text-primary-500 shrink-0">
                                <div className="flex h-full w-full items-center justify-center overflow-hidden text-center transition-all duration-300 rounded-full">
                                  <RandomAvatar className="max-h-full max-w-full object-cover shadow-sm dark:border-transparent h-10 w-10" customKey={i} />
                                </div>
                              </div>
                              <div>
                                <h4 className="font-heading text-sm font-light leading-tight text-muted-800 dark:text-white">
                                  <span>{order.name}</span>
                                </h4>
                                <p className="mb-0 text-gray-500 dark:text-white/70 text-xs flex font-normal"> {order.line_items.length} items
                                  <svg className="fill-primary w-4 h-4 ltr:ml-2 rtl:mr-2" xmlns="http://www.w3.org/2000/svg" width="100" height="100" enableBackground="new 0 0 100 100" viewBox="0 0 100 100">
                                    <path className="fill-blue-500" d="M88.057,45.286l-5.456-5.455c-1.295-1.295-2.356-3.854-2.356-5.689v-7.715c0-3.67-2.998-6.668-6.667-6.67h-7.718  c-1.833,0-4.395-1.063-5.69-2.357l-5.455-5.455c-2.592-2.592-6.836-2.592-9.428,0l-5.455,5.459c-1.296,1.295-3.861,2.355-5.69,2.355  h-7.715c-3.665,0-6.667,2.998-6.667,6.668v7.715c0,1.828-1.061,4.395-2.356,5.689l-5.456,5.455c-2.594,2.592-2.594,6.836,0,9.432  l5.456,5.455c1.296,1.295,2.356,3.861,2.356,5.689v7.715c0,3.666,3.002,6.668,6.667,6.668h7.715c1.833,0,4.395,1.061,5.69,2.355  l5.455,5.457c2.592,2.59,6.836,2.59,9.428,0l5.455-5.457c1.296-1.295,3.857-2.355,5.69-2.355h7.718c3.669,0,6.667-3.002,6.667-6.668  v-7.715c0-1.836,1.062-4.395,2.356-5.689l5.456-5.455C90.647,52.122,90.647,47.878,88.057,45.286z M44.709,65.001L30,50.29  l4.714-4.713l9.996,9.996l20.577-20.572L70,39.714L44.709,65.001z"></path>
                                  </svg>
                                </p>
                              </div>
                              <div className="ms-auto flex items-center">
                                <span
                                  className={`text-sm px-2 py-1 text-sm font-semibold rounded ${order.financial_status !== "paid"
                                    ? 'bg-yellow-500 text-white'
                                    : 'bg-green-500 text-white'
                                    }`}
                                >
                                  {order.financial_status !== "paid" ? "Pending" : "Paid"}
                                </span>
                              </div>
                            </div>
                          </div>
                        </div >
                      ))
                    ) : (
                      <div className="text-center text-black-700 px-2 py-2 rounded relative" role="alert">
                        No orders found.
                      </div>
                    )}
                  </div>
                )
                }
              </div >


              {/* vendor-show */}
              {/* 
              <h3 className="mt-6 text-xl">Users</h3>

              <TableHandler columnsFileds={vendorDataFileds} data={data} ></TableHandler> */}
            </>
          )
      }

    </>

  );
}

export default Dashboard;
